import css from 'styled-jsx/css'

import { breakpoints } from '@lno/core/constants'

export const styles: any = css`
  .partner-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.5rem;
    padding: 0.25rem 0;
    box-sizing: border-box;
    margin: 0.5rem 0;
    min-height: 4.4rem;
  }

  .partner-item__link {
    transition: opacity 0.2s ease-out;
    will-change: opacity;
  }

  .partner-item__link:hover {
    opacity: 0.6;
    transition-timing-function: ease-in;
  }

  .partner-item__img {
    margin: auto;
    width: 100%;
  }

  @media ${breakpoints.desktop} {
    .partner-item {
      margin: 0.5rem;
    }
  }
`
