import clsx from 'clsx'

import { BASE_URL } from '@lno/core/constants'

import { ButtonLink } from '@lno/core/components/ButtonLink'
import { Typography } from '@lno/core/components/Typography'
import { PartnersList } from './PartnersList'

import { PARTNERS_LIST_MAP } from '@lno/screens/Home/components/PartnersSection/data'

import { GASelectContentEvent } from '@lno/core/modules/analytics/events'
import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

export function PartnersSection() {
  const buttonLabel = 'Ver todas'
  const handleEvent = () => GASelectContentEvent.consultCnpjEvent(buttonLabel)

  return (
    <section className="partners-section">
      <div className="partners-section__container">
        <Typography
          size="headingM"
          color="high"
          tag="h2"
          className={clsx(jsxScope, 'partners-section__title')}
        >
          Negocie dívidas com as seguintes empresas
        </Typography>

        <Typography
          tag="p"
          size="bodyM"
          color="low"
          className={clsx(jsxScope, 'partners-section__description')}
        >
          Bancos, financeiras, redes de telefonia, varejo, empresas de
          recuperação de crédito e outras.
        </Typography>

        <div>
          <PartnersList data={PARTNERS_LIST_MAP} />
        </div>

        <div className="partners-section__cta-wrapper">
          <ButtonLink
            href={`${BASE_URL}/parceiros`}
            color="accent"
            variant="outlined"
            className={clsx(jsxScope, 'partners-section__cta')}
            role="button"
            onClick={handleEvent}
          >
            {buttonLabel}
          </ButtonLink>
        </div>
      </div>

      <style jsx>{styles}</style>
    </section>
  )
}

export default PartnersSection
