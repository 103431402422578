import css from 'styled-jsx/css'

import { breakpoints } from '@lno/core/constants'

export const styles: any = css`
  .step {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13.75rem;
    height: 5rem;
    padding-bottom: 1rem;
    margin: 0 1rem;
  }

  .step__title {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    line-height: 1.25rem;
  }

  .step__number {
    width: 2rem;
    height: 2.5rem;
    font-size: 2.25rem;
    color: var(--theme-primary);
    padding: 0;
    margin-right: 1rem;
  }

  .step__number--active_campaign {
    color: var(--theme-primary);
  }

  .step__img {
    position: absolute;
    top: 85%;
    left: -0.5rem;
    width: calc(100% + 1rem);
    overflow: hidden;
  }

  @media ${breakpoints.desktop} {
    .step {
      margin: 0 2.4rem;
    }
  }
`
