import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .media-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .media-list__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% / 2 - 2rem);
    margin: 1rem;
    box-sizing: border-box;
    list-style: none;
    min-height: 2.7rem;
  }

  .media-list__link {
    transition: opacity 0.2s ease-out;
    will-change: opacity;
  }

  .media-list__link:hover {
    opacity: 0.6;
    transition-timing-function: ease-in;
  }

  .media-list__logo {
    max-width: 100%;
    width: 9.375rem;
    height: 100%;
  }

  @media ${breakpoints.tablet} {
    .media-list__item {
      width: calc(100% / 4 - 2rem);
    }
  }

  @media ${breakpoints.desktop} {
    .media-list {
      max-width: 58rem;
      margin: 1.25rem auto 0;
    }
  }
`
