import { Link } from '@lno/components/Link'
import { VisuallyHidden } from '@lno/components/VisuallyHidden'

import { styles } from './styles'

import MEDIAS from '../data'

export const MediaList = () => (
  <ul className="media-list">
    {MEDIAS.map((media) => (
      <li key={media.id} className="media-list__item">
        <Link href={media.url} className="media-list__link" external>
          <img
            src={media.img1x}
            srcSet={`${media.img1x} 1x ${
              media.img2x ? `, ${media.img2x} 2x` : ''
            }`}
            alt=""
            className="media-list__logo"
            draggable="false"
            loading="lazy"
          />
          <VisuallyHidden>{media.title}</VisuallyHidden>
        </Link>
      </li>
    ))}

    <style jsx>{styles}</style>
  </ul>
)

MediaList.defaultProps = {
  medias: [],
}

export default MediaList
