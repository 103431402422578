import {
  EventAction,
  EventArea,
  EventCategory,
  EventContentType,
} from '@lno/core/modules/analytics/constants'

export enum CampaignLabel {
  CardName = 'card-descontos-de-ate-',
}

export const GA_EVENT_PAYLOADS = {
  buttonClick: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_card_meio_03',
    contentType: EventContentType.ButtonFeirao,
    action: EventAction.ButtonClickFeirao,
    area: EventArea.Home,
  },
  cta: {
    category: EventCategory.LnoHome,
    itemId: 'limpa-nome_web_botao_meio_04',
    contentType: EventContentType.Button,
    action: EventAction.ButtonClick,
    area: EventArea.Home,
    itemText: 'conhecer-todos-os-parceiros',
    label: 'conhecer-todos-os-parceiros',
  },
}
