import { EventArea, EventCategory } from '@lno/modules/analytics'

const BANNER_SLOT_SIZES = {
  mobile: [
    [300, 250],
    [336, 280],
    [320, 50],
    [320, 100],
  ],
  desktop: [
    [970, 90],
    [970, 250],
    [728, 90],
    [300, 250],
    [336, 280],
  ],
}

export const ADVERTISING_CONFIG = {
  HOME_TOP: {
    id: 'homeTop',
    adUnitPath: `area_deslogada_top`,
    size: BANNER_SLOT_SIZES,
  },
  HOME_MIDDLE: {
    id: 'homeMiddle',
    adUnitPath: `area_deslogada_middle`,
    size: BANNER_SLOT_SIZES,
  },
}

const PAGE_SETTINGS_VARIANTS = {
  phaseTwo: {
    title: 'Negocie suas dívidas com Serasa Limpa Nome',
    description:
      'Aproveite a fase 2 do Feirão Serasa Limpa Nome e negocie suas dívidas com o desconto do desenrola.',
  },
  lastDaysPhaseTwo: {
    title: 'Negocie suas dívidas com Serasa Limpa Nome',
    description:
      'Aproveite a fase 2 do Feirão Serasa Limpa Nome e negocie suas dívidas com o desconto do desenrola.',
  },
  lastDay: {
    title: 'Aproveite as últimas horas do Feirão Serasa Limpa Nome',
    description:
      'O Feirão Limpa Nome está acabando! Aproveite as últimas horas para negociar dívidas com descontos e condições especiais. Não perca essa oportunidade.',
  },
  lastDays: {
    title:
      'Últimos dias do Feirão Serasa Limpa Nome. Negocie agora suas dívidas.',
    description:
      'Aproveite os último dias Feirão Limpa Nome para negociar suas dívidas com descontos e condições especiais. Não perca essa oportunidade! Quitar dívidas com descontos é no Feirão Serasa Limpa Nome.',
  },
  begin: {
    title:
      'Feirão Limpa Nome. Negocie dívidas com até 99% de desconto na Serasa',
    description:
      'No MegaFeirão Serasa Limpa Nome e Desenrola você encontra mais de 700 parceiros com condições especiais para negociar suas dívidas. Consulte grátis as ofertas disponíveis aplicativo da Serasa e organize sua vida financeira.',
  },
  default: {
    title: 'Desenrola Brasil: Negocie dívidas com Serasa Limpa Nome.',
    description:
      'Aproveite para negociar e ficar livre de dívidas. No Serasa Limpa Nome você pode encontrar descontos para o Desenrola Brasil. Acesse agora e confira os descontos disponíveis.',
  },
}

type CampaignStage = Exclude<keyof typeof PAGE_SETTINGS_VARIANTS, 'default'>
export const getPageSettings = (stage?: CampaignStage) => {
  if (!stage) return PAGE_SETTINGS_VARIANTS['default']
  return PAGE_SETTINGS_VARIANTS[stage]
}

export const PAGE_SETTINGS = {
  title: 'Negociação de dívidas em até 24x sem juros | Serasa Limpa Nome',
  description:
    'Descubra agora se possui uma oferta disponível para negociar suas dívidas em até 24x sem juros e volte a ter crédito no mercado!',
}

export const PAGE_CAMPAIGN_SETTINGS = {
  title:
    'Negociar Dívida com até 99% de Desconto | Feirão Serasa Limpa Nome 2022',
  description:
    'Aproveite as ofertas do Feirão Limpa Nome [março/2022] para negociar dívidas com descontos que cabem no seu bolso e volte a ter crédito no mercado!',
}

export const EVENTS_GA = {
  PAGE_VIEW: {
    title: 'Limpa Nome - Home',
    path: '/limpa-nome-online/',
    area: EventArea.Home,
    label: 'home',
    category: EventCategory.LnoHome,
  },
}

export const EVENTS_GA_WEB = {
  PAGE_VIEW: {
    title: 'Limpa Nome - Home',
    path: '/limpa-nome-online/web',
    area: EventArea.Home,
    label: 'home',
    category: EventCategory.LnoHome,
  },
}
