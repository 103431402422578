import clsx from 'clsx'
import { styles } from '../styles'
const jsxScope = `jsx-${styles.__hash}`

interface Props {
  images?: {
    desktop: string
    mobile: string
    alt: string
  }
}

export const DesenrolaBrasilImage = ({ images }: Props) => {
  const hasImages = images?.desktop || images?.mobile
  if (!images || !hasImages) return null

  return (
    <div className={clsx(jsxScope, 'debt-negotiation-program-section__img')}>
      <picture>
        <source
          type="image/webp"
          srcSet={`${images.mobile}?format=webp`}
          media="(max-width: 599px)"
        />
        <source
          type="image/webp"
          srcSet={`${images.desktop}?format=webp`}
          media="(min-width: 600px)"
        />
        <source srcSet={images.mobile} media="(max-width: 599px)" />
        <source srcSet={images.desktop} media="(min-width: 600px)" />
        <img
          src={images.mobile}
          alt={images.alt}
          role="presentation"
          width={226}
          height={316}
        />
      </picture>
    </div>
  )
}
