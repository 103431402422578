/* eslint-disable jsx-control-statements/jsx-jcs-no-undef */
import { loadScriptJS } from '@lno/core/utils/script'
import { reportError } from '@lno/modules/dynatrace'
import { Monitoring } from '@lno/modules/monitoring'

import {
  REFRESH_KEY,
  REFRESH_TIMEOUT,
  REFRESH_VALUE,
  TimeoutSlot,
  ViewportSizeConfig,
  SlotConfig,
} from '../constants'

const isAdvertisingReady = (callback: () => void) => {
  try {
    window.googletag.cmd.push(() => {
      callback()
    })
  } catch (error) {
    reportError(error)
    Monitoring.reportError(error)
  }
}

const refreshAdserver = (event: googletag.events.Event) => {
  const slot = event.slot as TimeoutSlot

  if (slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) === -1) {
    return
  }

  if (slot.timeout) {
    clearTimeout(slot.timeout)
  }

  slot.timeout = setTimeout(() => {
    slot.setTargeting('refreshed_slot', 'true')
    window.googletag.pubads().refresh([slot])
    slot.timeout = null
  }, REFRESH_TIMEOUT)
}

const generateSizeMap = (size: ViewportSizeConfig) => {
  let sizeMap = window.googletag.sizeMapping()

  if (!size) {
    return sizeMap.addSize([0, 0], [0, 0]).build()
  }

  if (size.mobile) {
    sizeMap = sizeMap.addSize([0, 0], size.mobile)
  }

  if (size.tablet) {
    sizeMap = sizeMap.addSize([600, 200], size.tablet)
  }

  if (size.desktop) {
    sizeMap = sizeMap.addSize([980, 200], size.desktop)
  }

  if (size.custom) {
    size.custom.forEach((item) => {
      sizeMap = sizeMap.addSize(item.viewport, item.size)
    })
  }

  return sizeMap.build()
}

export const fallbackScriptAdvertising = () => {
  window.gptadslots = window.gptadslots || {}
  window.googletag = window.googletag || { cmd: [] }
}

export const loadScriptAdvertising = async () => {
  const advertisingScriptId = 'advertising-script'
  const advertisingScript = window.document.getElementById(advertisingScriptId)

  if (advertisingScript) {
    return
  }

  window.googletag.cmd.push(() => {
    window.googletag.pubads().collapseEmptyDivs()
    window.googletag.pubads().setCentering(true)
    window.googletag
      .pubads()
      .addEventListener('impressionViewable', refreshAdserver)
    window.googletag.pubads().enableLazyLoad({
      fetchMarginPercent: 4,
      renderMarginPercent: 300,
      mobileScaling: 2.0,
    })

    window.googletag.enableServices()
  })

  return loadScriptJS(
    advertisingScriptId,
    'https://www.googletagservices.com/tag/js/gpt.js'
  )
}

const getFullAdUnitPath = (path: string) => {
  return `/281426761/serasa_limpanomeonline/${path}`
}

export const defineSlot = (elementId: string, slotConfig: SlotConfig) => {
  isAdvertisingReady(() => {
    const sizeMap = generateSizeMap(slotConfig.size)
    const slot = window.googletag
      .defineSlot(getFullAdUnitPath(slotConfig.adUnitPath), [0, 0], elementId)
      .defineSizeMapping(sizeMap)
      .setTargeting(REFRESH_KEY, REFRESH_VALUE)
      .setTargeting('refreshed_slot', 'false')
      .addService(window.googletag.pubads())

    window.gptadslots[elementId] = slot
    window.googletag.display(elementId)
    window.googletag.setAdIframeTitle('Banner de anúncio')
  })
}

export const destroySlot = (id: string) => {
  isAdvertisingReady(() => {
    try {
      const slot = window.gptadslots[id]
      if (slot && slot.timeout) {
        clearTimeout(slot.timeout)
        slot.timeout = null
      }

      window.googletag.destroySlots([slot])
      delete window.gptadslots[id]
    } catch (error) {
      throw new Error('Erro ao destruir banner')
    }
  })
}

export const generateAdvertisingId = (elementId: string) => `ad-${elementId}`
