import { useContext } from 'react'
import { CmsContext } from '@lno/screens/Home/providers/CmsProvider'
import { useCampaign } from '../useCampaign'

export const useCountdown = (hasCountdown: boolean) => {
  const { countdown } = useContext(CmsContext)
  const { campaign, isActiveCampaign } = useCampaign()

  const hasCampaignCountdown = !!(
    isActiveCampaign && campaign!.data.hero.hasCountdown
  )
  const hasRegularCountdown = !!(hasCountdown && countdown)

  const startDate = hasCampaignCountdown
    ? campaign?.startDate
    : countdown?.startDate
  const endDate = hasCampaignCountdown ? campaign?.endDate : countdown?.endDate
  const hasDate = !!startDate && !!endDate

  const countdownProps = {
    startDate,
    endDate,
  } as {
    startDate: number
    endDate: number
  }

  const shouldShowCountdown =
    (hasCampaignCountdown || hasRegularCountdown) && hasDate

  return {
    shouldShowCountdown,
    countdownProps: shouldShowCountdown ? countdownProps : null,
  }
}
