import css from 'styled-jsx/css'

import { breakpoints } from '@lno/core/constants'

export const styles: any = css`
  @media ${breakpoints.desktop} {
    .debt-negotiation-program-section__content-wrapper {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-rows: 1fr;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto auto;
      gap: 0px 2rem;
      grid-template-areas:
        'image title '
        'image description '
        'image button ';
    }

    .debt-negotiation-program-section__img {
      width: 25.625rem;
      display: flex;
      align-items: center;
      justify-content: center;

      grid-area: image;
    }

    .debt-negotiation-program-section__title {
      grid-area: title;
    }

    .debt-negotiation-program-section__description {
      grid-area: description;
      a {
        font-weight: normal;
      }
    }

    .debt-negotiation-program-section__button {
      margin-top: 2rem;
      grid-area: button;
    }
  }

  .debt-negotiation-program-section {
    background-color: var(--fonts-snow);
    padding: 3rem 0 3rem 0;

    @media ${breakpoints.tabletOnly} {
      padding-top: 2rem;
    }

    &__link {
      color: var(--fonts-accent);
      font-weight: bold;
    }

    &__content-wrapper {
      margin: auto;
      max-width: 73.125rem;
      @media ${breakpoints.desktopDown} {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
      @media (max-width: 1232px) {
        margin: 0 1.5rem;
      }
    }
  }

  .debt-negotiation-program-section__title-wrapper {
    @media ${breakpoints.desktop} {
      margin-bottom: 2rem;
      max-width: 37.5rem;
    }
  }

  .debt-negotiation-program-section__title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    @media ${breakpoints.bigDesktopDown} {
      margin: 0;
      text-align: start;
    }
  }

  @media ${breakpoints.tablet} {
    .debt-negotiation-program-section__title {
      margin: 0 auto 1rem;
    }
  }

  @media ${breakpoints.desktop} {
    .debt-negotiation-program-section__title {
      margin: 0 auto 1rem;
      width: 100%;
    }
  }

  @media ${breakpoints.desktopDown} {
    .debt-negotiation-program-section {
      padding-bottom: 2rem;
    }

    .debt-negotiation-program-section__title {
      margin: 0 auto;
      width: 100%;
      line-height: normal;
    }

    .debt-negotiation-program-section__img {
      margin: 1.5rem auto;
    }

    .debt-negotiation-program-section__button {
      margin: 2rem auto 0;
    }
  }
`
