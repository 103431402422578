import { useCountdown } from '@lno/core/hooks/useCountdown'
import clsx from 'clsx'
import { useRef } from 'react'
import { useHeroEvent } from '../useHeroEvent'
import { Arguments } from './components/Arguments'
import { Countdown } from './components/Countdown'
import { CtaBlock } from './components/CtaBlock'
import { Disclaimer } from './components/Disclaimer'
import { Header } from './components/Header'
import { HeroImage } from './components/Image'
import { styles } from './styles'
import type { CmsHeroProps } from './types'

export function CmsHero({ props }: CmsHeroProps) {
  const {
    title,
    subtitle,
    cta,
    image,
    disclaimers,
    showArgumentsSection,
    hasCountdown,
  } = props
  const { shouldShowCountdown, countdownProps } = useCountdown(hasCountdown)
  const ref = useRef<HTMLElement>(null)

  const label = `banner-feirao-junho-2024-sao-joao`
  useHeroEvent({ label, ref })

  return (
    <section className="cms-hero" ref={ref}>
      <div
        className={clsx('cms-hero__container', {
          '--countdown': shouldShowCountdown && countdownProps,
        })}
      >
        <Header title={title} subtitle={subtitle} />
        {shouldShowCountdown && countdownProps && (
          <div className="cms-hero__schedules">
            <Countdown {...countdownProps} />
          </div>
        )}
        <HeroImage image={image} />
        <CtaBlock cta={cta} event={label} />
      </div>

      <Arguments shouldDisplay={showArgumentsSection} />
      <Disclaimer disclaimers={disclaimers} />

      <style jsx>{styles}</style>
    </section>
  )
}
