import { Typography } from '@lno/core/components/Typography'
import clsx from 'clsx'

import { BENEFITS, BENEFITS_CAMPAIGN } from './constants'

import ButtonLink from '@lno/core/components/ButtonLink'
import { LN_LOGIN_URL } from '@lno/core/constants'
import { useCampaign } from '@lno/core/hooks/useCampaign'
import { selectContentEvent } from '@lno/core/modules/analytics'
import { GASelectEventsPayloads } from '@lno/core/modules/analytics/constants'
import HorizontalListWithIcons from '../HorizontalListWithIcons'
import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

interface Props {
  className?: string
}

export function BenefitsSection({ className }: Props) {
  const { isActiveCampaign } = useCampaign()
  const handleEvent = () => {
    return selectContentEvent(
      GASelectEventsPayloads.buttonBenefitsSectionLearnMore
    )
  }
  return (
    <section className={clsx('benefits-section', className)}>
      <div>
        <div className="benefits-section__title-wrapper">
          <Typography
            size="headingM"
            color="high"
            className={clsx(jsxScope, 'benefits-section__title')}
            tag="h2"
          >
            {isActiveCampaign
              ? 'Por que escolher o MegaFeirão Serasa Limpa Nome e Desenrola?'
              : 'Por que negociar suas dívidas com o Serasa Limpa Nome?'}
          </Typography>
          <Typography size="bodyM" color="medium" tag="p">
            {isActiveCampaign
              ? 'Saiba quais são as vantagens que você encontra por aqui'
              : 'Saiba quais são as vantagens que você encontra por aqui.'}
          </Typography>
        </div>

        <HorizontalListWithIcons
          items={isActiveCampaign ? BENEFITS_CAMPAIGN : BENEFITS}
        />

        <ButtonLink
          href={LN_LOGIN_URL}
          color="primary"
          onClick={handleEvent}
          className={clsx(jsxScope, 'benefits-section__button')}
        >
          Saiba mais
        </ButtonLink>
      </div>
      <style jsx>{styles}</style>
    </section>
  )
}

BenefitsSection.defaultProps = {}

export default BenefitsSection
