import clsx from 'clsx'

import { Grid } from '@lno/components/Grid'

import Post from '@lno/core/components/CrossBlog/Post'

import { MEDIA } from './constants'

import { styles } from '@lno/core/components/CrossBlog/styles'
import Typography from '@lno/core/components/Typography'
import { GASelectContentEvent } from '@lno/core/modules/analytics/events'

const jsxScope = `jsx-${styles.__hash}`

export function CampaignMediaList() {
  return (
    <Grid
      tag="section"
      className={clsx(jsxScope, 'cross-blog', 'cross-blog__media')}
    >
      <Typography size="headingM" tag="h2">
        Serasa Limpa Nome na mídia
      </Typography>
      <div className="cross-blog__content">
        <ol className="cross-blog__post-list">
          {MEDIA.map((post, idx) => (
            <Post
              handleClick={() => GASelectContentEvent.viewMedia(post.label)}
              key={idx}
              title={post.title}
              url={post.url}
              image={post.image}
              className={clsx(jsxScope, 'cross-blog__post-item')}
              buttonLabel="Ver reportagem"
              extern={post.extern}
            />
          ))}
        </ol>
      </div>

      <style jsx>{styles}</style>
    </Grid>
  )
}
