import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .feedback-item {
    padding-top: 2rem;
    text-align: left;
    max-width: 17rem;
    margin: 0 auto;
  }

  .feedback-item__ballon {
    padding: 2rem 1rem;
    margin-bottom: 0.5rem;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #ececec;
    border-radius: 1.25rem 1.25rem 1.25rem 0.2rem;
    background-color: #fff;
    background-image: none;
    color: rgba(0, 0, 0, 0.66);
  }

  @media ${breakpoints.tablet} {
    .feedback-item:first-child {
      margin-right: 2rem;
    }
  }

  @media ${breakpoints.desktop} {
    .feedback-item {
      max-width: 18rem;
    }

    .feedback-item:first-child {
      padding-right: 1rem;
    }
  }
`
