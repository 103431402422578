import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .step-list__scroll {
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
  }

  .step-list__wrapper {
    display: inline-flex;
    align-items: flex-start;
    padding: 0 2rem 16rem;
    overflow: hidden;
  }

  @media ${breakpoints.desktop} {
    .step-list__scroll {
      overflow-x: hidden;
    }

    .step-list__wrapper {
      padding: 0 0 14rem;
    }
  }
`
