import clsx from 'clsx'
import LazyLoad from 'react-lazyload'

import { Typography } from '@lno/components/Typography'

import type { ReactNode } from 'react'
import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

type ListItemWithIconProps = {
  title: string
  description: string | ReactNode
  image: string
}

export const ListItemWithIcon = ({
  title,
  description,
  image,
}: ListItemWithIconProps) => (
  <li className="list-item">
    <LazyLoad height={92} offset={100} once>
      <div className="list-item__icon-wrapper">
        <img src={image} className="list-item__icon" alt={title} aria-hidden />
      </div>
    </LazyLoad>
    <div>
      <Typography
        size="headingXS"
        color="high"
        className={clsx(jsxScope, 'list-item__title')}
        tag="h3"
      >
        {title}
      </Typography>
      <Typography
        tag="p"
        size="bodyP"
        color="medium"
        className={clsx(jsxScope, 'list-item__description')}
      >
        {description}
      </Typography>
    </div>

    <style jsx>{styles}</style>
  </li>
)

export default ListItemWithIcon
