import { getDescription } from './description'
import { getStopwatchSchedules } from './schedules'
import { getTitle } from './title'

const campaignValidToStayActive = (
  startTimeCampaign: number,
  periodBetweenEndStart: number
) => Date.now() >= startTimeCampaign && periodBetweenEndStart > 0

const activateBellTimer = (startStopwatch: number) =>
  Date.now() >= startStopwatch

interface buildPayloadTimer {
  startTimeCampaign: number
  startStopWatch: number
  periodBetweenEndStart: number
}

export const getPayloadTimer = ({
  startTimeCampaign,
  startStopWatch,
  periodBetweenEndStart,
}: buildPayloadTimer) => {
  if (campaignValidToStayActive(startTimeCampaign, periodBetweenEndStart)) {
    if (activateBellTimer(startStopWatch)) {
      const schedulesCurrent = getStopwatchSchedules(periodBetweenEndStart)

      return {
        title: getTitle(periodBetweenEndStart),
        schedules: schedulesCurrent,
        description: getDescription(schedulesCurrent),
        campaignIsActive: true,
        stopWatchCampignIsActive: true,
      }
    } else {
      return {
        title: '',
        schedules: undefined,
        description: undefined,
        campaignIsActive: true,
        stopWatchCampignIsActive: false,
      }
    }
  }

  return {
    title: '',
    schedules: undefined,
    description: undefined,
    campaignIsActive: false,
    stopWatchCampignIsActive: false,
  }
}
