import iconCelular from '@assets/images/feirao/celular-desenrola.svg'
import iconPix from '@assets/images/feirao/pix.svg'
import iconSeloSemFraude from '@assets/images/feirao/selo-sem-fraude-white.svg'
import iconSucessoConta from '@assets/images/feirao/sucesso-conta-bancaria.svg'

import { LN_WEBFILES_LANDING } from '@lno/core/constants/urls'
import EnergyDebtsBenefit from './EnergyDebtsBenefit'

const imgOffers = `${LN_WEBFILES_LANDING}/benefits/offers.svg`
const imgSecurity = `${LN_WEBFILES_LANDING}/benefits/security.svg`
const imgAgreements = `${LN_WEBFILES_LANDING}/benefits/agreements.svg`

export const BENEFITS = [
  {
    title: 'Ofertas exclusivas',
    description:
      'Negocie suas dívidas com até 90% de desconto e condições especiais.',
    image: imgOffers,
  },
  {
    title: 'Confiável e seguro',
    description:
      'Negocie em poucos minutos, com a segurança que só a Serasa pode oferecer.',
    image: imgSecurity,
  },
  {
    title: 'Gerencie seus acordos',
    description:
      'Emita 2ª Via e acompanhe o andamento de seus acordos e parcelamentos.',
    image: imgAgreements,
  },
]

export const BENEFITS_CAMPAIGN = [
  {
    title: 'Ofertas do Desenrola',
    description:
      'Consulte todas as ofertas e acesse a plataforma do programa com rapidez e segurança',
    image: iconCelular,
  },
  {
    title: 'Descontos exclusivos',
    description: EnergyDebtsBenefit,
    image: iconPix,
  },
  {
    title: 'Parcele suas negociações',
    description:
      'Encontre ofertas com parcelamento (que pode chegar a 72x) ou à vista via Pix ou boleto',
    image: iconSucessoConta,
  },
  {
    title: 'MegaFeirão Sem Fraude',
    description:
      'Acesse nossos canais oficiais e finalize seu pagamento com segurança',
    image: iconSeloSemFraude,
  },
]
