import type { Countdown } from '@lno/core/models/Countdown'
import type { Hero } from '@lno/core/models/Hero'
import type { LnoContent } from '@lno/core/models/LnoContent'
import type { MetaTags } from '@lno/core/models/MetaTags'
import type { PropsWithChildren } from 'react'
import { createContext } from 'react'

export const CmsProvider = ({ content, children }: CmsProviderProps) => {
  const hero = content?.data.hero.value?.data || null
  const metaTags = content?.data.metaTags?.value?.data || null
  const countdown = content?.data.countdown?.value?.data || null

  return (
    <CmsContext.Provider
      value={{
        hero,
        metaTags,
        countdown,
      }}
    >
      {children}
    </CmsContext.Provider>
  )
}

type CmsContextProps = {
  hero: Hero | null
  metaTags: MetaTags | null
  countdown: Countdown | null
}

const defaultCmsContext = {
  hero: null,
  metaTags: null,
  countdown: null,
}

export const CmsContext = createContext<CmsContextProps>(defaultCmsContext)

type CmsProviderProps = PropsWithChildren<{ content: LnoContent | null }>
