import { ReactNode } from 'react'
import clsx from 'clsx'

import { styles } from './styles'

type GridProps = {
  children: ReactNode
  className?: string
  tag?: 'div' | 'section'
}

export function Grid({ children, className, tag: Comp }: GridProps) {
  if (!Comp) return null

  return (
    <Comp className={clsx(className, 'grid')}>
      {children}
      <style jsx>{styles}</style>
    </Comp>
  )
}

Grid.defaultProps = {
  tag: 'div',
}

export default Grid
