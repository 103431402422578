import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .media-section {
    background-color: #f5f7f9;

    &__grid {
      padding: 2rem 0.5rem;
      text-align: center;
    }

    @media ${breakpoints.desktop} {
      &__grid {
        padding: 2rem 0.5rem;
      }
    }
  }
`
