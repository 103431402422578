import css from 'styled-jsx/css'

import breakpoints from '@lno/core/constants/breakpoints'

export const styles: any = css`
  .hero__disclaimer--grid {
    text-align: left;
    width: 80%;
    max-width: 25rem;
    margin: -5rem auto 0;
    display: grid;
    grid-template-columns: 1fr;
  }

  .hero__disclaimer--grid::before {
    content: '';
  }

  .hero {
    background-color: var(--theme-primary);
    padding-top: 4.125rem;
  }

  .hero__container {
    display: grid;
    position: relative;
    margin-bottom: 15.75rem;
  }

  .hero__title {
    font-size: 1.75rem;
    line-height: 2rem;
    text-align: left;
    width: 80%;
    max-width: 25rem;
    margin: 0 auto 1rem;
  }

  .hero__description {
    text-align: left;
    width: 80%;
    max-width: 25rem;
    margin: 0 auto;
    margin-bottom: 0.5rem;
  }

  .hero__picture {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 0;

    &--default {
      margin: 0;
      height: 100%;
    }
  }

  .hero__picture__box {
    height: 100%;
    max-height: 25rem;

    @media ${breakpoints.desktopDown} {
      max-width: 9rem;
    }
  }

  .hero__img {
    width: 100%;
    max-width: 14.875rem;
    height: auto;

    &--default {
      height: 100%;
    }
  }

  .hero__cta-wrapper {
    margin: 0 1.25rem -10rem;
    min-width: 0;
  }

  .hero__schedules {
    margin: 0 auto;
  }

  @media ${breakpoints.tablet} {
    .hero__img {
      max-width: 22rem;
    }
  }

  @media ${breakpoints.desktop} {
    .hero__disclaimer--grid {
      max-width: var(--container-size);
      padding: 0 2rem;
      margin: -7rem auto 4rem;
      display: grid;
      grid-template-columns: 27.25rem 1fr;
    }

    .hero__disclaimer {
      max-width: calc(100vw / 3);
    }

    .hero__schedules {
      margin: 0;
    }

    .hero {
      padding-top: 6.25rem;
      margin: 0;

      &__container {
        width: 100%;
        max-width: calc(var(--container-size) + 4rem);
        padding: 0 2rem;
        margin: 0 auto 8rem;
        grid-template-rows: 4.625rem auto auto auto 5.875rem;
      }

      &__title {
        font-size: 2.625rem;
        line-height: 3rem;
        text-align: left;
        margin: 0;
        grid-row: 2;
        grid-column: 1;
        max-width: 32.5rem;
        width: 100%;
      }

      &__schedules {
        grid-row: 4;
        grid-column: 1;
      }

      &__description {
        display: block;
        grid-column: 1;
        grid-row: 3;
        text-align: left;
        margin: 1rem 0 0;
        max-width: 32.5rem;
        width: 100%;
      }

      &__picture {
        grid-column: 2;
        align-self: flex-start;

        &--default {
          grid-row: 2 / span 5;
        }

        &__campaign {
          grid-row: 3 / span 5;
        }
      }

      &__img {
        max-width: 29.375rem;
        width: auto;

        &--default {
          margin: 0;
        }
      }

      &__cta-wrapper {
        position: absolute;
        grid-row: 5;
        grid-column: 1;
        margin: 0;
      }

      &__cta {
        margin: 0;
      }
    }
  }
`
