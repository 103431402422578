import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .options-list {
    list-style: none;
    padding-top: 1rem;
  }

  @media ${breakpoints.tablet} {
    .options-list {
      display: flex;
      justify-content: center;
    }
  }
`
