import { useEffect, useState } from 'react'
import clsx from 'clsx'

import { useViewport } from '@lno/core/hooks/useViewport'

import { defineSlot, destroySlot, generateAdvertisingId } from '../../utils'
import { SlotConfig } from '../../constants'

import { useAdvertising } from '../../hooks/useAdvertising'

import { styles } from './styles'

type AdvertisingProps = {
  config: SlotConfig
  viewport?: 'desktop' | 'mobile'
  fixedBottom?: boolean
  className?: string
}

export function Advertising({
  config,
  fixedBottom,
  viewport,
  className,
}: AdvertisingProps) {
  const [elementId, setElementId] = useState(generateAdvertisingId(config.id))
  const currentViewport = useViewport()
  const { isLoaded } = useAdvertising()
  const isHidden = viewport && viewport !== currentViewport.key

  useEffect(() => {
    setElementId(generateAdvertisingId(config.id))
  }, [config])

  useEffect(() => {
    if (elementId && isLoaded) {
      defineSlot(elementId, config)
    }

    return () => destroySlot(elementId)
  }, [elementId, isLoaded])

  const finalClassname = clsx(className, 'advertising', {
    'advertising--fixed-bottom': fixedBottom,
    'advertising--hidden': isHidden,
  })

  return (
    <>
      <div className="advertising-wrapper">
        <div id={elementId} className={finalClassname} />
      </div>
      <style jsx>{styles}</style>
    </>
  )
}

Advertising.defaultProps = {
  className: null,
  viewport: null,
  fixedBottom: false,
}

export default Advertising
