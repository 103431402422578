import clsx from 'clsx'
import LazyLoad from 'react-lazyload'

import { Grid } from '@lno/components/Grid'
import { Typography } from '@lno/components/Typography'

import { MediaList } from './MediaList'

import { useCampaign } from '@lno/core/hooks/useCampaign'
import { CampaignMediaList } from './CampaignMediaList'
import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

export const MediaSection = () => {
  const { isActiveCampaign } = useCampaign()
  return (
    <div className={clsx(jsxScope, 'media-section')}>
      <Grid
        tag="section"
        className={clsx(jsxScope, { 'media-section__grid': !isActiveCampaign })}
      >
        {!isActiveCampaign && (
          <Typography size="headingM" tag="h2">
            Estamos na mídia
          </Typography>
        )}

        <LazyLoad height={290} once>
          {isActiveCampaign ? <CampaignMediaList /> : <MediaList />}
        </LazyLoad>

        <style jsx>{styles}</style>
      </Grid>
    </div>
  )
}

export default MediaSection
