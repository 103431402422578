import type { ReactNode } from 'react'
import LazyLoad from 'react-lazyload'
import clsx from 'clsx'

import { Typography } from '@lno/components/Typography'

import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

type OptionDescriptionProps = {
  linkClassName: string
}

type OptionItemProps = {
  title: string
  renderDescription(props: OptionDescriptionProps): ReactNode
  image: string
}

export const OptionItem = ({
  title,
  renderDescription,
  image,
}: OptionItemProps) => (
  <li className="option-item">
    <LazyLoad height={92} offset={100} once>
      <img
        src={image}
        className="option-item__icon"
        alt={title}
        width="92"
        height="92"
        aria-hidden
      />
    </LazyLoad>
    <div>
      <Typography
        size="headingXS"
        color="high"
        className={clsx(jsxScope, 'option-item__title')}
        tag="h2"
      >
        {title}
      </Typography>
      <Typography
        size="bodyM"
        color="high"
        tag="p"
        className={clsx(jsxScope, 'option-item__description')}
      >
        {renderDescription({
          linkClassName: clsx(jsxScope, 'option-item__link'),
        })}
      </Typography>
    </div>

    <style jsx>{styles}</style>
  </li>
)
