import { GASelectContentEvent } from '@lno/core/modules/analytics/events'

export const ESCAPE_THE_BLOW_STEPS = [
  <span key={0}>
    Entre no aplicativo
    <a
      href="https://play.google.com/store/apps/details?id=br.com.serasaexperian.consumidor&hl=pt_BR&gl=US"
      target="_blank"
      rel="noreferrer"
      onClick={() => GASelectContentEvent.selectDealOption('googlePlay')}
    >
      {' '}
      (Google Play{' '}
    </a>
    ou na
    <a
      href=" https://apps.apple.com/br/app/serasa-consulta-cpf/id1102452668"
      target="_blank"
      rel="noreferrer"
      onClick={() => GASelectContentEvent.selectDealOption('appStore')}
    >
      {' '}
      Apple Store){' '}
    </a>
    ou site da
    <a
      href="https://www.serasa.com.br/"
      target="_blank"
      rel="noreferrer"
      onClick={() => GASelectContentEvent.selectDealOption('serasa')}
    >
      {' '}
      Serasa.{' '}
    </a>
  </span>,
  'Realize o login na plataforma e clique em “soluções”.',
  'Selecione a opção “validar meio de pagamento”.',
  'Preencha o campo com o código Pix ou boleto recebido. Pronto! Se o seu código for confiável, você já pode pagar o seu acordo com segurança!',
  'Caso o número não seja identificado pelo Validador de Boletos, a Serasa sugere que o usuário verifique os dados (inclusive o beneficiário), antes de realizar o pagamento.',
]
