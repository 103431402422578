import { scopedClsx } from '@lno/core/utils/styled'

import type { PartnerCardProps } from '../core/types'
import { CompanyLogo } from '../core/CompanyLogo'
import { Card } from '../core/Card'
import { styles } from '../styles'
import ButtonLink from '@lno/core/components/ButtonLink'
import {
  getCampaignLabel,
  getPartnerNameAndCategory,
} from '@lno/screens/Feirao/core/analytics/utils'
import { selectContentEvent } from '@lno/core/modules/analytics'
import { GA_EVENT_PAYLOADS } from '@lno/screens/Feirao/core/analytics/constants'
import { useRef } from 'react'

const cl = scopedClsx(styles)

export function PartnerCard({
  partner,
  className,
  type,
  hasMoreTwoGoldPartner,
}: PartnerCardProps) {
  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const handleOnClick = () => {
    const partnerNameAndCategory = getPartnerNameAndCategory(partner.name, type)
    selectContentEvent({
      ...GA_EVENT_PAYLOADS.buttonClick,
      label: getCampaignLabel(partner.discount),
      partner: partnerNameAndCategory,
      itemText: getCampaignLabel(partner.discount),
    })
  }

  const buttonProps = {
    ref: buttonRef,
    onClick: handleOnClick,
    'aria-label': `Negocie com ${partner.name} com descontos de até ${partner.discount} porcento`,
  }

  return (
    <>
      <Card
        className={cl(hasMoreTwoGoldPartner ? 'card_more' : 'card', className)}
        partner={partner}
        type={type}
      >
        <div className={cl('partner-card__divider')}>
          <CompanyLogo image={partner.image} className={cl('logo')} />
        </div>
        <div className={cl('partner-card__divider-discount')} aria-hidden>
          <p className={cl('discount__description')}>
            <span className={cl('discount__text')}>Descontos de até</span>
            <span className={cl('discount__percentage')}>
              {partner.discount}%
            </span>
          </p>
        </div>
        <div className={cl('partner-card__divider-button')}>
          <ButtonLink
            className={cl('rounded-tag')}
            href={partner.link || ''}
            color="none"
            role="button"
            {...buttonProps}
          >
            Negocie agora
          </ButtonLink>
        </div>
      </Card>

      <style jsx>{styles}</style>
    </>
  )
}
