import { styles } from '../styles'
import { CompanyLogoProps } from './types'
import { scopedClsx } from '@lno/core/utils/styled'
const cl = scopedClsx(styles)

export const CompanyLogo = ({
  image,
  className,
  ...props
}: CompanyLogoProps) => (
  <picture className={cl('partner-card__picture')}>
    <source
      type="image/webp"
      srcSet={`${image.mobile}?format=webp`}
      media="(max-width: 599px)"
    />
    <source
      type="image/webp"
      srcSet={`${image.desktop}?format=webp`}
      media="(min-width: 600px)"
    />
    <source srcSet={image.mobile} media="(max-width: 599px)" />
    <source srcSet={image.desktop} media="(min-width: 600px)" />
    <img
      srcSet={`${image.mobile} 1x, ${image.desktop} 2x`}
      width="128px"
      height="64px"
      alt=""
      role="presentation"
      loading="lazy"
      className={className}
      {...props}
    />
  </picture>
)
