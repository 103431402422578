import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .faq-section {
    padding: 2rem 1.5rem;
    text-align: center;
  }

  .faq-section__list {
    width: 100%;
    margin: 2rem auto;
    list-style: none;
  }

  .faq-section__button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    justify-content: center;
  }

  .faq-section__button {
    width: 100%;
    max-width: 17rem;
    margin: 0.5rem 1.75rem 0;
  }

  @media ${breakpoints.tablet} {
    .faq-section__list {
      max-width: 45rem;
    }

    .faq-section__button-wrapper {
      flex-direction: row;
    }

    .faq-section__button {
      width: 16.5rem;
      margin: 0.5rem 0.5rem 0;
    }
  }
`
