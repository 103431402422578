import {
  EventAction,
  EventArea,
  EventCategory,
} from '@lno/core/modules/analytics'
import { sanitizeText } from '@lno/core/modules/analytics/utils'
import { PartnerTypes } from '../../components/PartnerSection/Partners/core/constants'
import { CampaignLabel } from './constants'

export const getCampaignLabel = (partnerDiscount: number) => {
  const completeLabel = `${CampaignLabel.CardName}${partnerDiscount}-por-cento`
  return `${sanitizeText(completeLabel)}%`
}

export const getPartnerNameAndCategory = (
  partnerName: string,
  partnerType: string
) => {
  if (partnerType == 'Others') return sanitizeText(`${partnerName}-outro`)
  return sanitizeText(`${partnerName}`)
}

interface GetViewContentPayloadProps {
  name: string
  discount: number
  type: PartnerTypes
}

export const getViewContentPayload = (partner: GetViewContentPayloadProps) => {
  return {
    area: EventArea.Home,
    category: EventCategory.LnoHome,
    action: EventAction.CardImpression,
    label: getCampaignLabel(partner.discount),
    partner: getPartnerNameAndCategory(partner.name, partner.type),
  }
}
