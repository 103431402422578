const FEEDBACK1_PREFIX =
  'De imediato, consegui o contato da empresa que eu estava negativada. Consegui com êxito'

export const FEEDBACKS = [
  {
    description: `${FEEDBACK1_PREFIX} a quitação da dívida pendente.`,
    author: 'Neivanilce Pereira Diniz',
  },
  {
    description:
      'Tive acesso à plataforma e consegui ver as dívidas de forma gratuita. Agradeço pela ajuda com as dívidas! Parabéns pelo site.',
    author: 'Nara Flávia',
  },
]
