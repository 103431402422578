import css from 'styled-jsx/css'

import { breakpoints, typography } from '@lno/constants'

export const styles: any = css`
  .list-item {
    display: flex;
    align-items: flex-start;
    padding: 0 0 2rem 0;
  }

  .list-item__icon-wrapper {
    min-width: 5rem;
    min-height: 4.25rem;
  }

  .list-item__icon {
    width: 4rem;
    margin-right: 1rem;
  }

  .list-item__title {
    padding-bottom: 0.5rem;
  }

  @media ${breakpoints.tablet} {
    .list-item {
      display: block;
      width: 16rem;
      text-align: center;
    }

    .list-item:not(:last-child) {
      margin-right: 1rem;
    }

    .list-item__icon {
      height: 5.75rem;
      width: 100%;
      margin: 0 0 1rem 0;
    }
  }

  @media ${breakpoints.desktop} {
    .list-item:not(:last-child) {
      margin-right: 2rem;
    }

    .list-item__description {
      ${typography.bodyM()}
    }
  }
`
