const FAQ = [
  {
    id: 1,
    title: 'Em quanto tempo a restrição sai do meu CPF?',
    content: `
        A dívida pode sair do seu CPF em um prazo de até 5 dias úteis após o
        pagamento. Caso este prazo já tenha passado e a dívida continue ativa em
        seu CPF, entre em contato diretamente com a empresa com a qual você
        negociou. Lembre-se que é uma obrigação da empresa credora solicitar a
        exclusão de dívida do cadastro de inadimplentes da Serasa Experian.
      `,
  },
  {
    id: 2,
    title:
      'Não concordo com as condições de pagamento apresentadas para mim. O que eu faço?',
    content: `
        As condições de pagamento disponíveis são fornecidas pelas empresas com
        as quais você tem dívidas. Em caso de dúvidas, entre em contato com a
        empresa pelos canais de atendimento apresentados no momento da
        negociação.
      `,
  },
  {
    id: 3,
    title: 'Quais dívidas podem ser negociadas no site do Limpa Nome?',
    content: `
        Apenas as dívidas disponibilizadas pelas empresas parceiras do Serasa
        Limpa Nome. É possível negociar <b>dívidas negativadas</b> ou
        <b>contas atrasadas (não negativadas).</b> Dívidas vencidas há mais de 5
        anos não são negativadas.
        <br />
        <br />
        <b>Atenção:</b> Não é possível negociar protestos, cheques sem fundo e
        falências.`,
  },
  {
    id: 4,
    title: 'Não encontrei a empresa que queria. E agora?',
    content: `
        Que chato que isso tenha acontecido. Mas não se preocupe, estamos
        trabalhando dia após dia para integrar cada vez mais parceiros em nosso
        site. Quem sabe a empresa com a qual você quer negociar não aparece por
        aqui amanhã? Fique de olho.
        <br />
        <br />
        Caso você possua outras dívidas além das disponibilizadas para
        negociação no Serasa Limpa Nome, deverá entrar em contato diretamente
        com os credores.
      `,
  },
  {
    id: 5,
    title:
      'Todas as dívidas no Serasa Limpa Nome estão registradas no cadastro de inadimplentes da Serasa Experian?',
    content: `
        Não. No Serasa Limpa Nome você também pode negociar dívidas em atraso
        que não estão e/ou serão registradas no cadastro de inadimplentes da
        Serasa Experian. Assim, nem todas as propostas no Limpa Nome são de
        dívidas negativadas. Você pode consultar a situação da sua dívida em
        nossa plataforma e tirar dúvidas diretamente com a empresa credora.
        Dívidas vencidas há mais de 5 anos não são incluídas no cadastro de
        inadimplentes.
      `,
  },
  {
    id: 6,
    title: 'Quais dívidas podem ser negociadas no Serasa Limpa Nome?',
    content: `
        Qualquer dívida que você tenha com as empresas parceiras do Serasa Limpa
        Nome. Atualmente, mais de 60% das dívidas dos brasileiros estão
        disponíveis para negociação com condições especiais na plataforma da
        Serasa. É possível negociar dívidas negativadas ou contas atrasadas.
      `,
  },
]

export default FAQ
