import Typography from '@lno/core/components/Typography'
import type { Hero } from '@lno/core/models/Hero'
import clsx from 'clsx'
import { styles } from '../styles'

const jsxScope = `jsx-${styles.__hash}`

export function Disclaimer({ disclaimers }: Pick<Hero, 'disclaimers'>) {
  if (!disclaimers || disclaimers?.length === 0) return null

  const regExp = /^(\*+)(.*)/

  return (
    <div className={clsx(jsxScope, 'cms-hero__disclaimer')}>
      {disclaimers.map(({ text }, index) => {
        const matches = text.match(regExp)
        const asterisks = matches && matches[1]
        const textWithoutAsterisks = (matches && matches[2]) || text
        return (
          <Typography
            key={`${text}-${index}`}
            tag="p"
            size="bodyM"
            className={clsx(jsxScope, 'cms-hero__disclaimer__text')}
            dangerouslySetInnerHTML={{
              __html: `<span aria-hidden>${asterisks}</span>${textWithoutAsterisks}`,
            }}
          />
        )
      })}
    </div>
  )
}
