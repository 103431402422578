export const loadScriptJS = (id: string, src: string) =>
  new Promise((resolve, reject) => {
    if (document.getElementById(id)) {
      resolve(null)
      return
    }

    const script = document.createElement('script')
    const fjs = document.getElementsByTagName('script')[0]

    const handleError = () => {
      script.remove()
      reject()
    }

    script.id = id
    script.async = true
    script.src = src
    script.onload = resolve
    script.onerror = handleError
    script.onabort = handleError

    fjs?.parentNode?.insertBefore(script, fjs)
  })
