import { useMemo } from 'react'

import { Grid } from '@lno/components/Grid'
import { Typography } from '@lno/components/Typography'
import { ButtonLink } from '@lno/components/ButtonLink'
import { CollapsibleSection } from '@lno/components/CollapsibleSection'
import { scopedClsx } from '@lno/core/utils/styled'

import { FaqSectionProps } from './types'
import { styles } from './styles'
import data from './data'
import { useCampaign } from '@lno/core/hooks/useCampaign'
import { GASelectContentEvent } from '@lno/core/modules/analytics/events'

const cl = scopedClsx(styles)

export const FaqSection = ({ schema }: FaqSectionProps) => {
  const { campaign, isActiveCampaign } = useCampaign()

  const doubts = isActiveCampaign && campaign ? campaign.data.faq || [] : data

  const strSchema = useMemo(
    () => (schema ? JSON.stringify(schema) : ''),
    [schema]
  )

  const defaultHeadingText = 'Tire suas dúvidas sobre o Limpa Nome'
  const activeCampaignHeadingText =
    'Tire suas dúvidas sobre o Feirão Limpa Nome'

  const sectionHeadingText = isActiveCampaign
    ? activeCampaignHeadingText
    : defaultHeadingText

  return (
    <section className="faq-section">
      <Grid className={cl('faq-section__container')}>
        <Typography
          tag="h2"
          size="headingM"
          color="high"
          className={cl('faq-section__title')}
        >
          {sectionHeadingText}
        </Typography>

        <div className="faq-section__list">
          {doubts.map((faq, index) => (
            <CollapsibleSection
              key={index}
              id={`faq-lno-${index}`}
              title={faq.title}
              content={faq.content}
            />
          ))}
        </div>

        <Typography size="headingS" color="high" tag="h2" weight="bold">
          Não encontrou sua resposta?&nbsp;
        </Typography>

        <div className="faq-section__button-wrapper">
          <ButtonLink
            onClick={GASelectContentEvent.goToHelpCenter}
            color="accent"
            variant="outlined"
            href="https://ajuda.serasa.com.br/hc/pt-br"
            className={cl('faq-section__button')}
            role="button"
          >
            Ir para Central de Ajuda
          </ButtonLink>
          <ButtonLink
            onClick={GASelectContentEvent.getInTouch}
            color="accent"
            variant="outlined"
            href="https://www.facebook.com/serasa.com.br"
            className={cl('faq-section__button')}
            aria-label="Fale conosco no Facebook, abre nova guia em página externa"
            role="button"
            external
          >
            Fale com a gente
          </ButtonLink>
        </div>

        {strSchema && <script type="application/ld+json">{strSchema}</script>}
      </Grid>

      <style jsx>{styles}</style>
    </section>
  )
}

export default FaqSection
