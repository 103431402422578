import clsx from 'clsx'

import { Typography } from '@lno/components/Typography'

import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

type BlogPostProps = {
  title: string
  url: string
  image: string
  imageWebp?: string
  className?: string
  buttonLabel?: string
  extern?: string
  handleClick?: () => void
}

export const BlogPost = (props: BlogPostProps) => {
  const {
    title,
    url,
    image,
    imageWebp,
    extern,
    className,
    buttonLabel,
    handleClick,
  } = props
  const label = extern
    ? `${title}, leia mais da reportagem ${extern}, abre nova guia em página externa`
    : `${title}, leia mais da publicação no blog Serasa.`
  return (
    <li className={clsx(className, 'post')}>
      <a
        onClick={handleClick}
        href={url}
        className="post__link"
        target="_blank"
        rel="noopener noreferrer"
        aria-label={label}
      >
        <div className="post__image-wrapper" aria-hidden>
          {imageWebp ? (
            <picture>
              <source srcSet={`${imageWebp}`} type="image/webp" />
              <img
                className="post__image"
                src={image}
                loading="lazy"
                alt=""
                height={294}
                width={181}
              />
            </picture>
          ) : (
            <img
              className="post__image"
              loading="lazy"
              src={image}
              height={294}
              width={181}
              alt=""
            />
          )}
        </div>

        <Typography
          tag="h2"
          size="bodyM"
          className={clsx(jsxScope, 'post__title')}
          aria-hidden
        >
          {title}
        </Typography>

        <Typography
          tag="p"
          size="bodyM"
          className={clsx(jsxScope, 'post__button')}
          align="center"
          weight="bold"
          color="accent"
          aria-hidden
        >
          {buttonLabel ? buttonLabel : 'Ver post no blog'}
        </Typography>
      </a>

      <style jsx>{styles}</style>
    </li>
  )
}

BlogPost.defaultProps = {
  className: '',
}

export default BlogPost
