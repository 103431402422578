import Typography from '@lno/core/components/Typography'
import { VisuallyHidden } from '@lno/core/components/VisuallyHidden'
import { useViewport } from '@lno/core/hooks'
import clsx from 'clsx'
import { styles } from '../styles'
import type { PaymentDetailsProps } from '../types'
import { IMAGE_TEXT, getArgumentsContent } from './utils'

const jsxScope = `jsx-${styles.__hash}`

function PaymentDetails({ text, image }: PaymentDetailsProps) {
  const accessibleText = `${text} ${IMAGE_TEXT[image.description]}`
  return (
    <>
      <div className="cms-hero__arguments__payment-details">
        <Typography
          className={jsxScope}
          size="bodyP"
          tag="span"
          color="snow"
          weight="normal"
          aria-hidden
        >
          {text}
        </Typography>
        <VisuallyHidden tag="p">{accessibleText}</VisuallyHidden>
        <img
          role="presentation"
          className={jsxScope}
          src={image.src}
          height={image.height}
          width={image.width}
          alt={`Imagem representativa com texto ${
            IMAGE_TEXT[image.description]
          }`}
        />
      </div>
      <hr
        className={clsx(jsxScope, 'cms-hero__arguments__wrapper__divider')}
        aria-hidden
      />
      <style jsx>{styles}</style>
    </>
  )
}

export const Arguments = ({ shouldDisplay }: { shouldDisplay?: boolean }) => {
  const { isMobile } = useViewport()
  if (!shouldDisplay) return null
  const contents = getArgumentsContent(isMobile)

  return (
    <div className="cms-hero__arguments">
      <div className="cms-hero__arguments__wrapper">
        {Object.entries(contents).map(([key, props]) => {
          return <PaymentDetails key={key} {...props} />
        })}
      </div>
      <style jsx>{styles}</style>
    </div>
  )
}
