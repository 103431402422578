import Typography from '@lno/core/components/Typography'
import ButtonLink from '@lno/core/components/ButtonLink'
import { scopedClsx } from '@lno/core/utils/styled'
import { selectContentEvent } from '@lno/core/modules/analytics'
import { usePartners } from '@lno/core/hooks/usePartners'

import { GA_EVENT_PAYLOADS } from '../../core/analytics/constants'
import { Partners } from './Partners/Partners'
import { PartnerTypes } from './Partners/core/constants'
import { styles } from './styles'

const cl = scopedClsx(styles)

export const PartnerSection = () => {
  const partners = usePartners()

  if (!partners) return null

  const goldType = { ...partners.data.tierOne }
  const hasGoldPartner = !!goldType.partners.length

  const silverType = { ...partners.data.tierTwo }
  const hasSilverPartner = !!silverType.partners.length

  const bronzeType = { ...partners.data.tierThree }
  const hasBronzePartner = !!bronzeType.partners.length

  const othersType = { ...partners.data.others }
  const hasOtherPartners = !!othersType.partners.length

  const cta = { ...partners.data.callToAction }

  const handleOnClick = () => {
    selectContentEvent({
      ...GA_EVENT_PAYLOADS.cta,
    })
  }

  return (
    <section className={cl('partners-section')}>
      <Typography
        tag="h2"
        size="headingL"
        className={cl('partners-section__title')}
      >
        {partners.data.label.showTitle && partners.data.label.title}
      </Typography>
      {hasGoldPartner && (
        <Partners
          partners={goldType.partners}
          showTitle={goldType.showTitle}
          subtitle={goldType.subtitle}
          label={goldType.label}
          type={PartnerTypes.Gold}
        />
      )}

      {hasSilverPartner && (
        <Partners
          partners={silverType.partners}
          showTitle={silverType.showTitle}
          subtitle={silverType.subtitle}
          label={silverType.label}
          type={PartnerTypes.Silver}
        />
      )}

      {hasBronzePartner && (
        <Partners
          partners={bronzeType.partners}
          showTitle={bronzeType.showTitle}
          subtitle={bronzeType.subtitle}
          label={bronzeType.label}
          type={PartnerTypes.Bronze}
        />
      )}

      {hasOtherPartners && (
        <Partners
          partners={othersType.partners}
          showTitle={othersType.showTitle}
          subtitle={othersType.subtitle}
          label={othersType.label}
          type={PartnerTypes.Others}
        />
      )}

      {cta && cta.showCta && (
        <ButtonLink
          href={cta.link}
          className={cl('partners-section__cta')}
          color="accent"
          variant="outlined"
          role="button"
          aria-label={cta.ariaLabel}
          tabIndex={0}
          onClick={handleOnClick}
        >
          {cta.label ? cta.label : 'Conhecer todos os parceiros'}
        </ButtonLink>
      )}

      <style jsx>{styles}</style>
    </section>
  )
}
