import capaG1 from '@assets/images/feirao/capa-g1.png'
import capaG1Webp from '@assets/images/feirao/capa-g1.webp'
import capaEstadao from '@assets/images/feirao/estadao.png'
import capaEstadaoWebp from '@assets/images/feirao/estadao.webp'
import capaVoceSa from '@assets/images/feirao/voce-sa.png'
import capaVoceSaWebp from '@assets/images/feirao/voce-sa.webp'

export const MEDIA = [
  {
    title: 'Feirão Limpa Nome negocia dívidas com descontos de até 99%',
    url: 'https://g1.globo.com/ce/ceara/noticia/2023/03/02/feirao-limpa-nome-negocia-dividas-com-descontos-de-ate-99percent.ghtml',
    image: capaG1,
    imageWebp: capaG1Webp,
    alt: '',
    extern: 'no G1',
    label: 'ver-reportagem-g1',
  },
  {
    title: 'Como funciona o feirão limpa nome do Serasa?',
    url: 'https://vocesa.abril.com.br/financas-pessoais/como-funciona-o-feirao-limpa-nome-do-serasa',
    image: capaVoceSa,
    imageWebp: capaVoceSaWebp,
    alt: '',
    extern: 'no Voce SA',
    label: 'ver-reportagem-voce-sa',
  },
  {
    title: 'Como pagar dívida por meio da Serasa?',
    url: 'https://einvestidor.estadao.com.br/educacao-financeira/como-pagar-divida-na-serasa/',
    image: capaEstadao,
    imageWebp: capaEstadaoWebp,
    alt: '',
    extern: 'no Estadão',
    label: 'ver-reportagem-estadao',
  },
]
