import { useCampaign } from '@lno/core/hooks/useCampaign'
import { STEPS, STEPS_CAMPAIGN } from '../constants'

import StepItem from '../StepItem'

import { styles } from './styles'

export const StepList = () => {
  const { isActiveCampaign } = useCampaign()
  const stepItems = isActiveCampaign ? STEPS_CAMPAIGN : STEPS
  return (
    <div className="step-list__scroll">
      <ol className="step-list__wrapper">
        {stepItems.map((step) => (
          <StepItem
            key={step.id}
            id={step.id}
            title={step.title}
            img={step.img}
          />
        ))}
      </ol>

      <style jsx>{styles}</style>
    </div>
  )
}

export default StepList
