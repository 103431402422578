import { breakpoints } from '@lno/core/constants'
import css from 'styled-jsx/css'

export const styles: any = css`
  .cronometer {
    display: flex;
    grid-column: 1;
    grid-row: 3;
    align-items: center;
    margin: 1rem 0;

    &__schedule {
      --digit-background: #fff;
      --digit-color: #e63888;
      --digit-label-color: #fff;
      --digit-line-height: 1rem;
      --digit-font-size: 1.5rem;
      --digit-height: 3rem;
      --digit-width: 2.375rem;
      --digit-border-radius: 0.5rem;
      --digit-text-transform: uppercase;

      margin: 0 0.313rem;
      display: flex;
      align-items: center;
      flex-direction: column;

      &__clock__label {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--digit-label-color);
      }

      &__clock {
        display: flex;

        &__item {
          display: flex;
        }

        &__separator {
          display: flex;
          align-items: center;
          padding: 0.25rem 0.5rem;
          text-align: center;
          font-size: var(--digit-font-size);
          height: var(--digit-height);
          color: var(--digit-label-color);
        }

        &__container {
          & + & {
            margin-left: 0.5rem;
          }
        }

        &__wrapper {
          display: flex;

          & + & {
            margin-left: 0.5rem;
          }
        }

        &__label {
          font-weight: bold;
          margin-top: 0.5rem;
        }

        &__digit {
          display: flex;
          justify-content: center;
          align-items: center;
          background: var(--digit-background);
          text-align: center;
          line-height: var(--digit-line-height, 2rem);
          font-size: var(--digit-font-size, 2.5rem);
          color: var(--digit-color);
          font-weight: bold;
          height: var(--digit-height, 3.5rem);
          width: var(--digit-width, 2.375rem);
          border-radius: var(--digit-border-radius, 0);
          text-transform: var(--digit-text-transform, 'uppercase');

          & + & {
            margin-left: 0.5rem;
          }
        }
        @media ${breakpoints.tabletDown} {
          &__digit {
            width: 2rem;
            height: 2.5rem;
            & + & {
              margin-left: 0.25rem;
            }
          }
        }
      }
    }
  }
`
