import type { Hero } from '@lno/core/models/Hero'
import { styles } from '../styles'

export const HeroImage = ({ image }: Pick<Hero, 'image'>) => (
  <div className="cms-hero__picture cms-hero__picture--default">
    <picture className="cms-hero__picture__box">
      <source
        type="image/webp"
        srcSet={`${image.mobile}?format=webp`}
        media="(max-width: 599px)"
      />
      <source
        type="image/webp"
        srcSet={`${image.desktop}?format=webp`}
        media="(min-width: 600px)"
      />
      <source srcSet={image.mobile} media="(max-width: 599px)" />
      <source srcSet={image.desktop} media="(min-width: 600px)" />
      <img
        className="cms-hero__img cms-hero__img--default"
        src={image.mobile}
        alt={image.alt}
        role="presentation"
        width={520}
        height={536}
      />
    </picture>
    <style jsx>{styles}</style>
  </div>
)
