import {
  ONE_HOUR_TIMESTAMP,
  ONE_MINUTE_TIMESTAMP,
  ONE_SECOND_TIMESTAMP,
} from '@lno/constants/time'

export const calcRemainingTime = (
  endDate: number,
  prorrogationTime: number | null = null,
  now = Date.now()
) => {
  const time =
    prorrogationTime && now < prorrogationTime ? prorrogationTime : endDate
  const remainingTime = time - Date.now()

  return remainingTime > 0 ? remainingTime : 0
}

const getDigits = (value: number, minDigits = 2, maxDigits = 2) => {
  const strValue = value.toString()
  const padLength =
    maxDigits && strValue.length >= maxDigits ? maxDigits : minDigits

  return strValue.padStart(padLength, '0').split('')
}

const getCountdownInfo = (hours: number, minutes: number, seconds: number) => {
  return [
    {
      digits: getDigits(hours),
      label: 'Horas',
    },
    {
      digits: getDigits(minutes),

      label: 'Minutos',
    },
    {
      digits: getDigits(seconds),

      label: 'Segundos',
    },
  ]
}

export const getCountdown = (timestamp: number) => {
  if (timestamp === 0) return getCountdownInfo(0, 0, 0)

  const hours = Math.floor(timestamp / ONE_HOUR_TIMESTAMP)

  const minutes = Math.floor(
    (timestamp - hours * ONE_HOUR_TIMESTAMP) / ONE_MINUTE_TIMESTAMP
  )

  const seconds = Math.floor(
    (timestamp - hours * ONE_HOUR_TIMESTAMP - minutes * ONE_MINUTE_TIMESTAMP) /
      ONE_SECOND_TIMESTAMP
  )

  return getCountdownInfo(hours, minutes, seconds)
}
