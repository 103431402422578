import {
  ONE_DAY_TIMESTAMP,
  ONE_HOUR_TIMESTAMP,
  ONE_MINUTE_TIMESTAMP,
  ONE_SECOND_TIMESTAMP,
} from '@lno/core/constants/time'
import { StopwatchSchedules } from '../types'

const getDigits = (value: number, minDigits = 2, maxDigits = 2) => {
  const strValue = value.toString()
  const padLength =
    maxDigits && strValue.length >= maxDigits ? maxDigits : minDigits

  return strValue.padStart(padLength, '0').split('')
}

const getTimerInfo = (info: number, label: string) => ({
  digits: getDigits(info),
  label: info === 1 ? label.slice(0, -1) : label,
})

const getStopwatchInfos = (
  days: number,
  hours: number,
  minutes: number,
  seconds: number,
  minimumHoursToDisplayHours: number
): StopwatchSchedules[] => {
  const daysInfo = getTimerInfo(days, '')
  const hoursInfo = getTimerInfo(hours, 'Horas')
  const minutesInfo = getTimerInfo(minutes, 'Minutos')
  const secondsInfo = getTimerInfo(seconds, 'Segundos')

  if (hours >= minimumHoursToDisplayHours) {
    return [daysInfo]
  } else {
    if (hours > 0) {
      return [hoursInfo, minutesInfo, secondsInfo]
    }

    return [minutesInfo, secondsInfo]
  }
}

export const getStopwatchSchedules = (
  timestamp: number,
  minimumHoursToDisplayHours: number = 72
): StopwatchSchedules[] => {
  if (timestamp === 0) getStopwatchInfos(0, 0, 0, 0, minimumHoursToDisplayHours)

  const days = Math.floor(timestamp / ONE_DAY_TIMESTAMP)
  const hours = Math.floor(timestamp / ONE_HOUR_TIMESTAMP)
  const minutes = Math.floor(
    (timestamp - hours * ONE_HOUR_TIMESTAMP) / ONE_MINUTE_TIMESTAMP
  )
  const seconds = Math.floor(
    (timestamp - hours * ONE_HOUR_TIMESTAMP - minutes * ONE_MINUTE_TIMESTAMP) /
      ONE_SECOND_TIMESTAMP
  )

  return getStopwatchInfos(
    days,
    hours,
    minutes,
    seconds,
    minimumHoursToDisplayHours
  )
}
