import { CmsContext } from '@lno/screens/Home/providers/CmsProvider'
import { useContext } from 'react'
import { useCampaign } from '../useCampaign'

export const useCmsHero = () => {
  const { campaign, isActiveCampaign } = useCampaign()
  const { hero } = useContext(CmsContext)
  if (!isActiveCampaign) return { hero, hasCmsHeroData: !!hero }
  return {
    hero: campaign!.data.hero,
    hasCmsHeroData: !!campaign!.data.hero,
  }
}
