import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .how-works-section {
    position: relative;
    background-color: #f6f8fa;
    text-align: center;
    color: #ffffff;
    &.--box-cta-active {
      margin-top: 4rem;
    }
  }

  .how-works-section__container {
    margin: 0 auto;
    padding-top: 2rem;
    &.--box-cta-active {
      margin-top: -4rem;
      padding-top: 0;
    }
  }

  .how-works-section__title {
    margin-bottom: 2rem;
    line-height: normal;
  }

  .how-works-section__box-cpf {
    position: relative;
    top: -6rem;
    margin: 0 1.5rem;
  }
  @media ${breakpoints.tablet} {
    .how-works-section__box-cpf {
      margin: 0 auto;
    }
  }
`
