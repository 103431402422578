import clsx from 'clsx'

import { Typography } from '@lno/core/components/Typography'

import { styles } from './styles'

import kv from '@assets/images/mao-na-roda/selo-sem-luz.png'
import kvDesk from '@assets/images/mao-na-roda/selo-sem-luz-desk.png'
import money from '@assets/images/mao-na-roda/100mil-fundo-ouro.png'
import gold from '@assets/images/mao-na-roda/barra-de-ouro.png'

import kvWebp from '@assets/images/mao-na-roda/selo-sem-luz.webp'
import kvDeskWebp from '@assets/images/mao-na-roda/selo-sem-luz-desk.webp'
import moneyWebp from '@assets/images/mao-na-roda/100mil-fundo-ouro.webp'
import goldWebp from '@assets/images/mao-na-roda/barra-de-ouro.webp'

import downloadAppIcon from '@assets/images/mao-na-roda/baixar-app.svg'
import useServicesIcon from '@assets/images/mao-na-roda/usar-servicos.svg'
import receiveNumbersIcon from '@assets/images/mao-na-roda/ganhar-numeros.svg'

import googlePlayIcon from '@assets/images/mao-na-roda/google-play.svg'
import appStoreIcon from '@assets/images/mao-na-roda/app-store.svg'

import { useIsContentInViewport } from '@lno/core/modules/analytics/hooks/useIsContentInViewport'
import { useRef } from 'react'
import { viewContentEvent } from '@lno/core/modules/analytics/utils/viewContentEvent'
import {
  EventAction,
  EventArea,
  EventCategory,
  EventContentType,
  selectContentEvent,
} from '@lno/core/modules/analytics'

const jsxScope = `jsx-${styles.__hash}`

interface Props {
  className?: string
}

const LABEL_GA =
  'banner-mao-na-roda-aproveite-os-servicos-da-serasa-e-concorra-a'
const HAND_ON_THE_WHEEL_END_DATE = process.env
  .HAND_ON_THE_WHEEL_END_DATE as string

const getStore = (type: 'apple' | 'google') => {
  const stores = {
    apple: 'app-store',
    google: 'google-play',
  }
  return stores[type]
}

const handleClickBanner = () =>
  selectContentEvent({
    category: EventCategory.LnoHome,
    itemId: 'serasa-web_web_banner_meio_01',
    contentType: EventContentType.Banner,
    action: EventAction.BannerClick,
    label: LABEL_GA,
    area: EventArea.Home,
    itemText: LABEL_GA,
  })

const handleClickDownloadApp = (type: 'google' | 'apple') =>
  selectContentEvent({
    category: EventCategory.LnoHome,
    itemId: 'serasa-web_web_botao_meio_03',
    contentType: `botao-baixe-o-app-e-participe-${getStore(type)}`,
    action: `clique-botao-baixe-o-app-e-participe-${getStore(type)}`,
    label: LABEL_GA,
    area: EventArea.Home,
    itemText: LABEL_GA,
  })

const callback = () =>
  viewContentEvent({
    label: LABEL_GA,
    action: EventAction.BannerView,
    area: EventArea.Home,
    category: EventCategory.LnoHome,
  })
const checkIsCampaignAvailable = () => {
  const now = new Date()
  const endDate = new Date(HAND_ON_THE_WHEEL_END_DATE)
  return now < new Date(endDate)
}
export function HandOnTheWheel({ className }: Props) {
  const isCampaignAvailable = checkIsCampaignAvailable()

  const ref = useRef<HTMLAnchorElement>(null)
  useIsContentInViewport({
    ref,
    callback,
  })

  if (!isCampaignAvailable) return null

  return (
    <section className={clsx(jsxScope, 'hand-on-the-wheel', className)}>
      <div
        className={clsx(
          jsxScope,
          'hand-on-the-wheel_inner-container',
          className
        )}
      >
        <div className={clsx(jsxScope, 'hand-on-the-wheel_left')}>
          <Typography
            tag="h2"
            size="headingS"
            color="snow"
            className={clsx(
              jsxScope,
              'hand-on-the-wheel_text',
              'hand-on-the-wheel_title'
            )}
          >
            Aproveite os serviços da Serasa e <b>concorra a:</b>
          </Typography>
          <div className={clsx(jsxScope, 'hand-on-the-wheel_benefits')}>
            <div className={clsx(jsxScope, 'hand-on-the-wheel_money')}>
              <picture>
                <source type="image/webp" srcSet={moneyWebp} />
                <img src={money} width={239} height={101} alt="" />
              </picture>
            </div>
            <div
              className={clsx(
                jsxScope,
                'hand-on-the-wheel_row',
                'hand-on-the-wheel_gold'
              )}
            >
              <Typography
                tag="p"
                size="bodyM"
                color="snow"
                className={clsx(
                  jsxScope,
                  'hand-on-the-wheel_text',
                  'hand-on-the-wheel_paragraph'
                )}
              >
                <b>PARA AJUDAR SUA VIDA FINANCEIRA</b>
              </Typography>
              <picture>
                <source type="image/webp" srcSet={goldWebp} />
                <img src={gold} width={66} height={42} alt="" />
              </picture>
            </div>
            <div className={clsx(jsxScope, 'hand-on-the-wheel_row')}>
              <Typography
                tag="p"
                size="bodyM"
                color="snow"
                className={clsx(
                  jsxScope,
                  'hand-on-the-wheel_text',
                  'hand-on-the-wheel_paragraph'
                )}
              >
                <b>+ 4 SESSÕES DE CONSULTORIA</b> para organizar as finanças
              </Typography>
            </div>
            <div className={clsx(jsxScope, 'hand-on-the-wheel_row')}>
              <Typography
                tag="p"
                size="bodyM"
                color="snow"
                className={clsx(
                  jsxScope,
                  'hand-on-the-wheel_text',
                  'hand-on-the-wheel_paragraph'
                )}
              >
                <b>+ 1 ANO DE ASSINATURA</b> do Serasa Premium{' '}
              </Typography>
            </div>
          </div>
        </div>
        <a
          aria-label="Visitar página da Campanha Mão na Roda"
          href="https://www.serasa.com.br/mao-na-roda/"
          ref={ref}
          onClick={handleClickBanner}
          className={clsx(jsxScope, 'hand-on-the-wheel_center')}
        >
          <picture className={clsx(styles['hand-on-the-wheel_image'])}>
            <source
              type="image/webp"
              srcSet={kvWebp}
              media="(max-width: 1300px)"
            />
            <source srcSet={kv} media="(max-width: 1300px)" />
            <source type="image/webp" srcSet={kvDeskWebp} />
            <img src={kvDesk} width={387} height={355} alt="" />
          </picture>
        </a>
        <div className={clsx(jsxScope, 'hand-on-the-wheel_right')}>
          <Typography
            tag="p"
            size="headingXS"
            color="snow"
            className={clsx(
              jsxScope,
              'hand-on-the-wheel_text',
              'hand-on-the-wheel_paragraph'
            )}
          >
            <b>
              Serão 6 sorteios. Quanto mais usar, mais chances de ganhar. Para
              participar é fácil:
            </b>
          </Typography>
          <ol>
            <li>
              <img src={downloadAppIcon} width={40} height={40} alt="" /> Baixe
              grátis o app Serasa
            </li>
            <li>
              <img src={useServicesIcon} width={40} height={40} alt="" />
              Use os serviços e produtos contemplados
            </li>
            <li>
              <img src={receiveNumbersIcon} width={40} height={40} alt="" />
              Ganhe um número da sorte
            </li>
          </ol>
          <div className={clsx(jsxScope, 'hand-on-the-wheel_download')}>
            <Typography
              tag="p"
              size="headingXS"
              color="snow"
              className={clsx(
                jsxScope,
                'hand-on-the-wheel_text',
                'hand-on-the-wheel_paragraph'
              )}
            >
              <b>Baixe o app e participe</b>
            </Typography>
            <ul>
              <li>
                <a
                  href="https://apps.apple.com/br/app/serasa/id1102452668"
                  aria-label="Baixe nosso aplicativo na App Store, abre nova guia em site externo"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleClickDownloadApp('apple')}
                >
                  <img src={appStoreIcon} width={137} height={49} alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=br.com.serasaexperian.consumidor"
                  aria-label="Baixe nosso aplicativo na Google Play Store, abre nova guia em site externo"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleClickDownloadApp('google')}
                >
                  <img src={googlePlayIcon} width={137} height={49} alt="" />
                </a>
              </li>
            </ul>
          </div>
          <Typography
            tag="p"
            size="bodyM"
            color="snow"
            className={clsx(jsxScope, 'hand-on-the-wheel_disclaimer')}
          >
            <b>Serasa,</b> fazendo sua vida andar.
          </Typography>
        </div>
      </div>
      <Typography
        size="bodyP"
        tag="p"
        className={clsx(jsxScope, 'hand-on-the-wheel_footer')}
        color="snow"
      >
        <span aria-hidden>*</span>Consulte condições de participação e
        regulamento completo na área promocional dentro do App Serasa ou na
        página oficial da promoção.
      </Typography>
      <style jsx>{styles}</style>
    </section>
  )
}

HandOnTheWheel.defaultProps = {}

export default HandOnTheWheel
