/* eslint-disable jsx-control-statements/jsx-jcs-no-undef */

export const REFRESH_KEY = 'refresh'
export const REFRESH_VALUE = 'true'
export const REFRESH_TIMEOUT = 30000

export interface TimeoutSlot extends googletag.Slot {
  timeout?: ReturnType<typeof setTimeout> | null
}

declare global {
  interface Window {
    googletag: googletag.Googletag
    gptadslots: {
      [key: string]: TimeoutSlot
    }
  }
}

export type ViewportSizeConfig = {
  mobile?: googletag.GeneralSize
  tablet?: googletag.GeneralSize
  desktop?: googletag.GeneralSize
  custom?: {
    viewport: googletag.SingleSizeArray
    size: googletag.GeneralSize
  }[]
}

export type SlotConfig = {
  id: string
  size: ViewportSizeConfig
  adUnitPath: string
}

export const BANNER_SIZES = {
  VERY_SMALL: [
    [320, 50],
    [320, 100],
  ],
  SMALL: [
    [300, 250],
    [336, 280],
  ],
  LARGE: [
    [970, 90],
    [728, 90],
  ],
  VERY_LARGE: [
    [970, 250],
    [970, 90],
    [728, 90],
  ],
  SKYSCRAPPER: [
    [120, 600],
    [160, 600],
    [300, 600],
  ],
}
