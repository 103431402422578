import { Link } from '@lno/components/Link'

import { OptionItem } from '../OptionItem'

import { OPTIONS } from '../constants'

import { GASelectContentEvent } from '@lno/core/modules/analytics/events'
import { styles } from './styles'

const OptionsList = () => (
  <ul className="options-list">
    <OptionItem
      {...OPTIONS.APP}
      renderDescription={({ linkClassName }) => (
        <>
          Baixe grátis nosso aplicativo no &nbsp;
          <Link
            onClick={() => GASelectContentEvent.selectDealOption('googlePlay')}
            className={linkClassName}
            href="https://play.google.com/store/apps/details?id=br.com.serasaexperian.consumidor&hl=pt_BR&gl=US"
            external
            aria-label="Baixar aplicativo Serasa no Google Play, abre nova guia em página externa"
          >
            Google Play
          </Link>
          &nbsp;ou na &nbsp;
          <Link
            onClick={() => GASelectContentEvent.selectDealOption('appStore')}
            className={linkClassName}
            href="https://apps.apple.com/br/app/serasa-consulta-cpf/id1102452668"
            external
            aria-label="Baixar aplicativo Serasa no App Store, abre nova guia em página externa"
          >
            App Store
          </Link>
          .
        </>
      )}
    />
    <OptionItem
      {...OPTIONS.WHATSAPP}
      renderDescription={({ linkClassName }) => (
        <>
          É simples e rápido, adicione nosso número&nbsp;
          <Link
            onClick={() => GASelectContentEvent.selectDealOption('whatsApp')}
            className={linkClassName}
            href="https://api.whatsapp.com/send?phone=5511995752096&text=Ol%C3%A1%20Bel!"
            external
            aria-label="Mande mensagem pro nosso WhatsApp: 11 9 9575 2096, abre nova guia em página externa"
          >
            (11) 99575-2096
          </Link>
          &nbsp;e mande mensagem para nós.
        </>
      )}
    />
    <OptionItem
      {...OPTIONS.MAIL}
      renderDescription={({ linkClassName }) => (
        <>
          São mais de 6 mil agências dos Correios pelo Brasil para negociar suas
          dívidas. &nbsp;
          <a
            onClick={() => GASelectContentEvent.selectDealOption('postOffice')}
            className={linkClassName}
            href="https://www.serasa.com.br/limpa-nome-online/correios"
            aria-label="Saiba mais sobre negociação de dívidas em agências dos Correios"
          >
            Saiba mais
          </a>
        </>
      )}
    />
    <style jsx>{styles}</style>
  </ul>
)
export default OptionsList
