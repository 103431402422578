import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .cross-blog-container {
    background-color: #f5f7f9;
    padding: 2rem 0;
  }

  .cross-blog {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .cross-blog__media {
    padding: 2rem 0;
  }

  .cross-blog__title {
    padding: 0 1rem;
    text-align: center;
  }

  .cross-blog__content {
    width: 100%;
    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
    padding: 2rem 0 2rem;
  }

  .cross-blog__post-list {
    display: inline-flex;
    list-style: none;
  }

  .cross-blog__post-item {
    width: 18.5rem;
  }

  .cross-blog__post-item:first-child {
    margin-left: 1rem;
  }

  @media ${breakpoints.desktop} {
    .cross-blog__content {
      display: flex;
      justify-content: center;
    }
  }
`
