import { useIsContentInViewport } from '@lno/core/modules/analytics/hooks/useIsContentInViewport'
import { viewContentEvent } from '@lno/core/modules/analytics/utils/viewContentEvent'

import { getViewContentPayload } from '@lno/screens/Feirao/core/analytics/utils'
import { ReactNode, useRef, useCallback } from 'react'
import { PartnerProps } from '../../types'
import type { PartnerTypes } from './constants'

interface CardProps {
  partner: PartnerProps
  type: keyof typeof PartnerTypes
  className: string
  children: ReactNode
}

export const Card = ({ partner, type, className, children }: CardProps) => {
  const eventSent = useRef<boolean>(false)
  const cardRef = useRef<HTMLAnchorElement | null>(null)

  const handleCardInViewport = useCallback(() => {
    if (!eventSent.current) {
      const payload = getViewContentPayload({
        name: partner.name,
        discount: partner.discount,
        type: type as PartnerTypes,
      })
      viewContentEvent(payload)
      eventSent.current = true
    }
  }, [partner.name, partner.discount, type])

  useIsContentInViewport({
    ref: cardRef,
    callback: handleCardInViewport,
  })

  return (
    <article
      className={className}
      style={partner.color ? { background: partner.color } : {}}
    >
      {children}
    </article>
  )
}
