import { styles } from './styles'

import PartnerItem from './PartnerItem'
import { Partner } from './types'

interface PartnersListProps {
  data: Partner[]
}

export const PartnersList = ({ data }: PartnersListProps) => {
  return (
    <div className="partner-list">
      {data.map((partner) => (
        <PartnerItem partner={partner} key={partner.id} />
      ))}

      <style jsx>{styles}</style>
    </div>
  )
}

export default PartnersList
