import css from 'styled-jsx/css'
import { breakpoints } from '@lno/core/constants'

export const styles: any = css`
  .partners-section {
    padding: 1.25rem 0.5rem 1rem;
    text-align: center;
    position: relative;
    z-index: 10;
  }

  .partners-section__container {
    padding: 0 0.5rem;
  }

  .partners-section__title,
  .partners-section__description {
    margin-bottom: 0.5rem;
  }

  .partners-section__cta-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;
  }

  .partners-section__cta {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 20rem;
    text-align: center;
  }

  .partners-section__cta:first-child {
    margin-bottom: 1rem;
  }

  @media ${breakpoints.tablet} {
    .partners-section {
      padding: 1.25rem 0;
    }
  }

  @media ${breakpoints.desktop} {
    .partners-section {
      padding: 3rem 0.5rem 1rem;
    }

    .partners-section__cta-wrapper {
      flex-direction: row;
      margin-left: 50%;
      margin-top: 1.75rem;
    }

    .partners-section__cta:first-child {
      margin: 0 0 0 -9rem;
    }
  }
`
