import { useEffect, useState } from 'react'
import clsx from 'clsx'

import { useScrollTop } from '@lno/hooks/useScrollTop'
import { useViewport } from '@lno/hooks/useViewport'
import { Viewport } from '@lno/constants/viewport'

import { NAVBAR_FIXED_OFFSET } from '../Navbar/constants'

import { styles } from './styles'
import { AnchorTestButton } from '../Navbar/AnchorTestButton'

export const AnchorFooter = () => {
  const [isFooterFixed, setFooterFixed] = useState(false)
  const [mode, setMode] = useState<Viewport | null>(null)
  const top = useScrollTop()
  const viewport = useViewport()

  useEffect(() => {
    setMode(viewport.key)
  }, [viewport])

  useEffect(() => {
    setFooterFixed(top > NAVBAR_FIXED_OFFSET)
  }, [top])

  const sectionStyles = clsx('footer-section', 'anchor__button-footer', {
    'footer-section--fixed': isFooterFixed,
  })

  return (
    <>
      {isFooterFixed && (
        <section className={sectionStyles}>
          <span className="footer-section__text">
            Consulte grátis ofertas para suas dívidas e negocie online
          </span>
          <AnchorTestButton
            showIcon={true}
            size={mode === Viewport.Desktop ? 'medium' : 'large'}
            className="anchor__button-footer"
          />
        </section>
      )}

      <style jsx>{styles}</style>
    </>
  )
}
