import { useCampaignTimer } from '@lno/core/hooks/useCampaignTimer'
import { InterestFreeCronometer } from '../../../../InterestFreeCronometer'

interface CountdownProps {
  endDate: number
  startDate: number
}

export const Countdown = ({ startDate, endDate }: CountdownProps) => {
  const countdownData = useCampaignTimer({
    endTimeCampaign: endDate,
    startStopWatch: startDate,
    startTimeCampaign: startDate,
  })

  return (
    <InterestFreeCronometer
      schedules={countdownData.schedules!}
      description={countdownData.description}
    />
  )
}
