import css from 'styled-jsx/css'

import { breakpoints } from '@lno/core/constants'

export const styles: any = css`
  .benefits-section {
    padding: 2rem 1.5rem;
    background-color: #f5f7f9;
  }

  .benefits-section__button {
    width: 100%;
    max-width: 240px;
    margin: 0 auto;
  }
  .benefits-section__title-wrapper {
    text-align: center;
    margin-bottom: 3rem;
  }

  .benefits-section__title {
    margin-bottom: 0.5rem;
  }

  @media ${breakpoints.tablet} {
    .benefits-section {
      padding: 2rem 0 8rem;
    }

    .benefits-section__title {
      width: 34.25rem;
      margin: 0 auto 1rem;
    }
  }

  @media ${breakpoints.desktop} {
    .benefits-section {
      padding: 2rem 0 2rem;
    }

    .benefits-section__title {
      margin: 0 auto 1rem;
      width: 100%;
    }
  }
  @media ${breakpoints.desktopDown} {
    .benefits-section {
      padding-bottom: 5rem;
    }
  }
`
