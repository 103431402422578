import clsx from 'clsx'
import type { ElementType } from 'react'

import { styles } from './styles'

import ButtonLink from '@lno/core/components/ButtonLink'
import Typography from '@lno/core/components/Typography'

import paymentCodeValidatorImg from '@assets/images/feirao/validador-boleto.gif'
import { useCampaign } from '@lno/core/hooks/useCampaign'
import {
  GASelectContentEvent,
  GAViewContentEvent,
} from '@lno/core/modules/analytics/events'
import { useIsContentInViewport } from '@lno/core/modules/analytics/hooks/useIsContentInViewport'
import { useCallback, useRef } from 'react'

import { HightlightedLink } from '../HightightedLink'
import { ESCAPE_THE_BLOW_STEPS } from './constants'

const PAYMENT_CHECK_LINK =
  'https://www.serasa.com.br/area-cliente/validador/meio-de-pagamento'

const jsxScope = `jsx-${styles.__hash}`

interface Props {
  boxComponent?: ElementType
  web?: boolean
}

export const PaymentCodeValidator = ({
  boxComponent: BoxComponent,
  web,
}: Props) => {
  const eventSent = useRef<boolean>(false)
  const sectionMiddleRef = useRef<HTMLElement | null>(null)
  const { isActiveCampaign } = useCampaign()

  const handleSectionInViewport = useCallback(() => {
    if (!eventSent.current) {
      GAViewContentEvent.paymentCodeValidator()
      eventSent.current = true
    }
  }, [])

  useIsContentInViewport({
    ref: sectionMiddleRef,
    callback: handleSectionInViewport,
  })

  return (
    <section className="escape-the-blow-section">
      {BoxComponent && (
        <BoxComponent
          id="cpf-input-middle"
          customText="Aproveite ofertas exclusivas para quitar suas dívidas"
          className={clsx(jsxScope, 'escape-the-blow-section__box-cpf')}
          web={web}
        />
      )}
      <div
        className={clsx('escape-the-blow-section__container', {
          '--box-cta-active': !!BoxComponent,
        })}
      >
        <img
          className="escape-the-blow-section__container__image"
          src={paymentCodeValidatorImg}
          alt=""
          width={180}
          height={348}
        />
        <div className="escape-the-blow-section__content">
          <Typography tag="h2" color="high" size="headingM">
            {!isActiveCampaign
              ? 'Passo a passo para acessar o “Validador de código de pagamento da Serasa”'
              : 'MegaFeirão sem fraude é na Serasa'}
          </Typography>

          {isActiveCampaign && (
            <Typography tag="p" color="high" size="bodyM">
              A Serasa atende somente pelos canais oficiais e não entra em
              contato direto com o consumidor. Encontrou algum perfil ou site
              semelhante aos canais da Serasa e desconfia da veracidade dele?
              Você pode registrar diretamente no canal de denúncias da Serasa,{' '}
              <HightlightedLink
                href="https://www.serasa.com.br/contra-fraudes/"
                target="_blank"
                rel="noreferrer"
                onClick={GASelectContentEvent.makeComplain}
              >
                clicando aqui
              </HightlightedLink>
              . Em caso de dúvidas, sempre confira a veracidade do boleto ou
              chave Pix antes de realizar o pagamento.
            </Typography>
          )}

          <ol className="escape-the-blow-section__steps">
            {isActiveCampaign && (
              <>
                <Typography tag="h3" size="headingXS">
                  <strong>
                    Passo a passo para acessar o “Validador de código de
                    pagamentos da Serasa".
                  </strong>
                </Typography>
                <Typography italic tag="span" size="bodyM">
                  *Ferramenta disponível para validação de acordos realizados
                  dentro da plataforma do Serasa Limpa Nome.
                </Typography>
              </>
            )}
            {ESCAPE_THE_BLOW_STEPS.map((step, index) => (
              <Typography
                key={index}
                tag="li"
                color="high"
                size="bodyM"
                weight="normal"
                className={clsx(
                  jsxScope,
                  'escape-the-blow-section__steps__item'
                )}
              >
                {step}
              </Typography>
            ))}
          </ol>

          <span ref={sectionMiddleRef} aria-hidden />
        </div>
      </div>
      <ButtonLink
        onClick={GASelectContentEvent.checkPaymentCode}
        href={PAYMENT_CHECK_LINK}
        variant="raised"
        color="primary"
        role="button"
      >
        Conferir código de pagamento
      </ButtonLink>
      <style jsx>{styles}</style>
    </section>
  )
}
