import { Description, StopwatchSchedules } from '../types'

export const getDescription = (
  schedules: StopwatchSchedules[]
): Description | undefined => {
  if (schedules.length === 1) {
    let end = 'dias para terminar!'

    if (schedules[0].digits[1]! === '1' && schedules[0].digits[0]! === '0')
      end = 'dia para terminar!'

    return {
      initial: 'Faltam',
      end,
    }
  }

  return
}
