import { breakpoints } from '@lno/core/constants'
import css from 'styled-jsx/css'

export const styles: any = css`
  .footer-section {
    background-color: var(--theme-primary);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    will-change: transform;
    z-index: 1020;
    padding: 0 2rem;
    min-height: 4rem;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
    font-size: 1.25rem;
    font-weight: 700;

    &__text {
      margin-right: 2rem;
    }

    &--fixed {
      z-index: 1030;
      position: fixed;
      animation-name: nav-fixed;
      animation-duration: 0.3s;
      box-shadow: rgb(0 0 0 / 10%) 0px 2px 4px 0px;
    }

    @keyframes nav-fixed {
      from {
        transform: translateY(3rem);
      }
      to {
        transform: translateY(0);
      }
    }
  }
  @media ${breakpoints.desktopDown} {
    .footer-section {
      flex-direction: column;
      padding: 1rem;
      font-size: 0.8rem;

      &__text {
        margin: 0 0 1rem;
      }
    }
  }
  .anchor__button-footer {
    display: none;
  }
`
