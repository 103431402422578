import { HightlightedLink } from '../HightightedLink'

const EnergyDebtsBenefit = (
  <>
    Negocie suas dívidas com mais de{' '}
    <HightlightedLink
      href="https://www.serasa.com.br/limpa-nome-online/parceiros/"
      target="_blank"
      rel="noreferrer"
    >
      700 empresas
    </HightlightedLink>{' '}
    e até 99% de desconto
  </>
)

export default EnergyDebtsBenefit
