import { Advertising } from '@lno/core/modules/advertising'

import { ADVERTISING_CONFIG } from '../../constants'

const HomeTop = () => {
  return <Advertising config={ADVERTISING_CONFIG.HOME_TOP} />
}

const HomeMiddle = () => {
  return <Advertising config={ADVERTISING_CONFIG.HOME_MIDDLE} />
}

export const Banners = {
  HomeTop,
  HomeMiddle,
}

export default Banners
