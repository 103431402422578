import Typography from '@lno/core/components/Typography'
import clsx from 'clsx'
import { useCallback } from 'react'

import { styles } from './styles'

import { useViewport } from '@lno/core/hooks'
import { StopwatchSchedules } from '@lno/core/hooks/useCampaignTimer/types'

const jsxScope = `jsx-${styles.__hash}`

interface Props {
  schedules: StopwatchSchedules[]
  description?: {
    initial: string
    end: string
  }
  className?: string
}

export const InterestFreeCronometer = ({
  description,
  schedules,
  className,
}: Props) => {
  const renderDigits = useCallback(
    (schedule: StopwatchSchedules) => (
      <div className="cronometer__schedule__clock__container">
        <div className="cronometer__schedule__clock__wrapper">
          {schedule &&
            schedule.digits.map((digit, index) => (
              <span
                key={index}
                className="cronometer__schedule__clock__digit"
                suppressHydrationWarning
              >
                {digit}
              </span>
            ))}
        </div>

        <Typography
          size="bodyM"
          tag="span"
          className={clsx(jsxScope, 'cronometer__schedule__clock__label')}
          color={null}
        >
          {schedule.label}
        </Typography>

        <style jsx>{styles}</style>
      </div>
    ),
    []
  )

  const { isMobile } = useViewport()
  const descriptionSize = isMobile ? 'headingXS' : 'headingS'

  return (
    <div className={clsx(className, 'cronometer')}>
      {description?.initial && (
        <Typography size={descriptionSize} tag="p" color="snow" weight="bold">
          {description.initial}
        </Typography>
      )}

      <div className="cronometer__schedule">
        <div className="cronometer__schedule__clock">
          {schedules &&
            schedules.map((schedule: StopwatchSchedules, index: number) => (
              <div className="cronometer__schedule__clock__item" key={index}>
                {renderDigits(schedule)}

                {schedules.length - 1 !== index && (
                  <span
                    className="cronometer__schedule__clock__separator"
                    aria-hidden="true"
                  >
                    :
                  </span>
                )}
              </div>
            ))}
        </div>
      </div>

      {description?.end && (
        <Typography size={descriptionSize} tag="p" color="snow" weight="bold">
          {description.end}
        </Typography>
      )}
      <style jsx>{styles}</style>
    </div>
  )
}
