import { createContext, useEffect, ReactNode, useState, useRef } from 'react'

import { fallbackScriptAdvertising, loadScriptAdvertising } from '../utils'

export const AdvertisingContext = createContext({ isLoaded: false })

type AdvertisingProviderProps = {
  children: ReactNode
}

export function AdvertisingProvider({ children }: AdvertisingProviderProps) {
  const [isLoaded, setLoaded] = useState(false)
  const mountedRef = useRef(false)

  useEffect(() => {
    mountedRef.current = true

    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    fallbackScriptAdvertising()

    setTimeout(async () => {
      await loadScriptAdvertising()

      if (mountedRef.current) {
        setLoaded(true)
      }
    }, 1000)
  }, [])

  return (
    <AdvertisingContext.Provider value={{ isLoaded }}>
      {children}
    </AdvertisingContext.Provider>
  )
}

export default AdvertisingProvider
