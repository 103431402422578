import schemaCampaign from '@assets/schemas/schema-FAQ-Feirao-2022.json'
import LazyLoad from 'react-lazyload'

import { CrossBlog } from '@lno/core/components/CrossBlog'
import { Footer } from '@lno/core/components/Footer'
import { AdvertisingProvider } from '@lno/core/modules/advertising'

import { clsx } from '@lno/core/utils/styled'

import { useCampaign } from '@lno/core/hooks/useCampaign'
import { PartnerSection as CampaignPartnersSection } from '@lno/screens/Feirao/components/PartnerSection'
import { WebTestProps } from '../../layouts'
import { Banners } from '../Banners'
import { BenefitsSection } from '../BenefitsSection'
import { DealsOptionsSection } from '../DealsOptionsSection'
import { FaqSection } from '../FaqSection'
import { FeedbacksSection } from '../FeedbacksSection'
import { HowWorksSection } from '../HowWorksSection'
import { MediaSection } from '../MediaSection'
import { PartnersSection } from '../PartnersSection'
import { PaymentCodeValidator } from '../PaymentCodeValidator'

import CrossEcred from '@lno/core/components/CrossEcred'
import { useCmsHero } from '@lno/core/hooks/useCmsHero'
import GovernmentDebtNegotiationProgramSection from '../GovernmentDebtNegotiationProgramSection'
import HandOnTheWheel from '../HandOnTheWheel'
import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

export const Content = ({ web }: WebTestProps) => {
  const { isActiveCampaign } = useCampaign()
  const { hero } = useCmsHero()
  const desenrolaSection = hero?.desenrolaSection

  return (
    <>
      <AdvertisingProvider>
        <GovernmentDebtNegotiationProgramSection data={desenrolaSection} />
        {isActiveCampaign ? <CampaignPartnersSection /> : <PartnersSection />}
        <Banners.HomeTop />
        <BenefitsSection className={clsx(jsxScope, 'benefits')} />
        <PaymentCodeValidator web={web} />
        <HowWorksSection />
        <HandOnTheWheel />
        <DealsOptionsSection />
        <FeedbacksSection />
        <Banners.HomeMiddle />
      </AdvertisingProvider>

      <LazyLoad offset={1500} height={2400} once>
        {isActiveCampaign ? (
          <FaqSection schema={schemaCampaign} />
        ) : (
          <FaqSection />
        )}
        <CrossBlog />
        <CrossEcred />
        <MediaSection />
        <Footer hasAnchorBanner />
      </LazyLoad>
      <style jsx>{styles}</style>
    </>
  )
}

export default Content
