import clsx from 'clsx'

import { ButtonLink } from '@lno/components/ButtonLink'
import { Grid } from '@lno/components/Grid'
import { Typography } from '@lno/components/Typography'

import Post from './Post'

import { POSTS, POSTS_CAMPAIGN } from './constants'

import { useCampaign } from '@lno/core/hooks/useCampaign'
import { GASelectContentEvent } from '@lno/core/modules/analytics/events'
import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

export const CrossBlog = () => {
  const { isActiveCampaign: isActive } = useCampaign()
  const posts = isActive ? POSTS_CAMPAIGN : POSTS

  return (
    <div className={clsx(jsxScope, 'cross-blog-container')}>
      <Grid tag="section" className={clsx(jsxScope, 'cross-blog')}>
        <Typography
          size="headingM"
          color="high"
          weight="bold"
          className={clsx(jsxScope, 'cross-blog__title')}
          tag="h2"
        >
          Conheça o blog do Serasa Limpa Nome
        </Typography>

        <div className="cross-blog__content">
          <ol className="cross-blog__post-list">
            {posts.map((post, idx) => (
              <Post
                handleClick={() =>
                  GASelectContentEvent.viewBlogPost(post.title)
                }
                key={idx}
                title={post.title}
                url={post.url}
                image={post.image}
                className={clsx(jsxScope, 'cross-blog__post-item')}
              />
            ))}
          </ol>
        </div>

        <ButtonLink
          onClick={GASelectContentEvent.viewMoreBlogPosts}
          color="accent"
          variant="outlined"
          href="https://www.serasa.com.br/ensina/seu-nome-limpo/"
          target="_blank"
          role="button"
        >
          Ver mais matérias no Blog
        </ButtonLink>
      </Grid>
      <style jsx>{styles}</style>
    </div>
  )
}

export default CrossBlog
