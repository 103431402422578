import { LN_WEBFILES_URL } from '@lno/core/constants'

const HOST_IMAGES = LN_WEBFILES_URL + '/partners'

const createPartner = (
  partnerKey: string,
  companyName: string,
  hasBlacklabel: boolean,
  partners: { customBlacklabel?: string },
  feirao: { discount: number | string | null }
) => ({
  partnerKey,
  companyName,
  hasBlacklabel,
  partners,
  feirao,
  title: `negociação ${companyName}`,
})

const items = [
  createPartner(
    'avon',
    'Avon',
    true,
    {},
    {
      discount: null,
    }
  ),
  createPartner(
    'nubank',
    'Nubank',
    true,
    {},
    {
      discount: null,
    }
  ),
  createPartner(
    'carrefour',
    'Carrefour',
    true,
    {},
    {
      discount: null,
    }
  ),
  createPartner(
    'claro-net',
    'Claro',
    true,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'credsystem',
    'CredSystem',
    true,
    {},
    {
      discount: 97,
    }
  ),
  createPartner(
    'hoepers',
    'Hoepers',
    true,
    {},
    {
      discount: 98,
    }
  ),
  createPartner(
    'ipanema',
    'Ipanema',
    true,
    {},
    {
      discount: 95,
    }
  ),
  createPartner(
    'marisa',
    'Marisa',
    true,
    {},
    {
      discount: 95,
    }
  ),
  createPartner(
    'itapeva',
    'Itapeva',
    true,
    {},
    {
      discount: 95,
    }
  ),
  createPartner(
    'itau',
    'Itaú',
    true,
    {},
    {
      discount: 90,
    }
  ),
  createPartner(
    'bb',
    'Banco do Brasil',
    true,
    {
      customBlacklabel: 'banco-do-brasil',
    },
    {
      discount: 90,
    }
  ),
  createPartner(
    'mgw',
    'MGW Ativos',
    true,
    {},
    {
      discount: 'sem-juros-10',
    }
  ),
  createPartner(
    'kroton',
    'Kroton',
    true,
    {},
    {
      discount: 'sem-juros-10',
    }
  ),
  createPartner(
    'oi',
    'Oi',
    true,
    {},
    {
      discount: null,
    }
  ),
  createPartner(
    'recovery',
    'Recovery',
    true,
    {
      customBlacklabel: 'negociar-recovery',
    },
    {
      discount: 99,
    }
  ),
  createPartner(
    'santander',
    'Santander',
    true,
    {},
    {
      discount: 90,
    }
  ),
  createPartner(
    'vivo',
    'Vivo',
    true,
    {},
    {
      discount: 90,
    }
  ),
  createPartner(
    'riachuelo',
    'Riachuelo',
    true,
    {},
    {
      discount: null,
    }
  ),
  createPartner(
    'tim',
    'Tim',
    true,
    {},
    {
      discount: 92,
    }
  ),
  createPartner(
    'ativos',
    'Ativos S.A.',
    true,
    {},
    {
      discount: 98,
    }
  ),
  createPartner(
    'atlantico',
    'Atlântico Fundo Crediativos',
    false,
    {},
    {
      discount: 'sem-juros-10',
    }
  ),
  createPartner(
    'algar',
    'Algar',
    true,
    {},
    {
      discount: 60,
    }
  ),
  createPartner(
    'net',
    'Claro Net',
    false,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'nextel',
    'Nextel',
    false,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'claro',
    'Claro',
    false,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'sky',
    'Sky',
    true,
    {},
    {
      discount: 92,
    }
  ),
  createPartner(
    'porto-seguro',
    'Porto Seguro',
    true,
    {},
    {
      discount: 95,
    }
  ),
  createPartner(
    'crediativos',
    'CrediAtivos',
    true,
    {},
    {
      discount: 'sem-juros-10',
    }
  ),
  createPartner(
    'maiscredit',
    'Mais Credit',
    false,
    {},
    {
      discount: 'sem-juros-10',
    }
  ),
  createPartner(
    'tribanco',
    'Tribanco',
    true,
    {},
    {
      discount: 98,
    }
  ),
  createPartner(
    'bmg',
    'BMG',
    true,
    {},
    {
      discount: 95,
    }
  ),
  createPartner(
    'cbss',
    'CBSS',
    true,
    {
      customBlacklabel: 'digio',
    },
    {
      discount: 80,
    }
  ),
  createPartner(
    'afinz',
    'Afinz',
    false,
    {},
    {
      discount: 70,
    }
  ),
  createPartner(
    'dscard',
    'Di Santinni',
    true,
    {
      customBlacklabel: 'di-santinni',
    },
    {
      discount: 97,
    }
  ),
  createPartner(
    'dmcard',
    'DM CARD',
    true,
    {
      customBlacklabel: 'dm-card',
    },
    {
      discount: 97,
    }
  ),
  createPartner(
    'digio',
    'Digio',
    true,
    {},
    {
      discount: 80,
    }
  ),
  createPartner(
    'pontofrio',
    'Ponto Frio',
    false,
    {},
    {
      discount: 90,
    }
  ),
  createPartner(
    'tenda',
    'Tenda',
    false,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'anhanguera',
    'Anhanguera',
    true,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'iuni',
    'Iuni',
    false,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'pitagoras',
    'Pitagoras',
    false,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'unic',
    'Unic',
    false,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'uniderp',
    'Uniderp',
    false,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'unirondon',
    'Unirondon',
    false,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'unopar',
    'Unopar',
    false,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'unime',
    'Unime',
    false,
    {},
    {
      discount: 85,
    }
  ),
  createPartner(
    'casas-bahia',
    'Casas Bahia',
    false,
    {},
    {
      discount: 90,
    }
  ),
  createPartner(
    'zema',
    'Zema',
    true,
    {},
    {
      discount: 'sem-juros',
    }
  ),
  createPartner(
    'crefisa',
    'Crefisa',
    true,
    {},
    {
      discount: 99,
    }
  ),
  createPartner(
    'c6',
    'C6 Bank',
    true,
    { customBlacklabel: 'c6-bank' },
    {
      discount: null,
    }
  ),
  createPartner(
    'bradesco',
    'Bradesco',
    true,
    {},
    {
      discount: 99,
    }
  ),
  createPartner(
    'renner',
    'Renner',
    true,
    {},
    {
      discount: 99,
    }
  ),
  createPartner(
    'caixa',
    'Caixa Ecnômica Federal',
    true,
    {},
    {
      discount: 99,
    }
  ),
  createPartner(
    'bv',
    'BV Financeira',
    true,
    {},
    {
      discount: 99,
    }
  ),
  createPartner(
    'bradescard',
    'Bradescard',
    false,
    {},
    {
      discount: 99,
    }
  ),
  createPartner(
    'senff',
    'SENFF',
    false,
    {},
    {
      discount: 99,
    }
  ),
  createPartner(
    'havan',
    'Lojas Havan',
    true,
    {},
    {
      discount: 99,
    }
  ),
  createPartner(
    'pernambucanas',
    'Lojas Pernambucanas',
    true,
    {},
    {
      discount: 99,
    }
  ),
  createPartner(
    'losango',
    'Losango',
    true,
    {},
    {
      discount: 90,
    }
  ),
  createPartner(
    'energisa',
    'Energisa',
    false,
    {},
    {
      discount: 99,
    }
  ),
  createPartner(
    'boticario',
    'Boticario',
    true,
    {},
    {
      discount: 90,
    }
  ),
  createPartner(
    'willbank-pag',
    'Will Bank',
    false,
    {},
    {
      discount: null,
    }
  ),
  createPartner(
    'pan',
    'Banco Pan',
    false,
    {},
    {
      discount: null,
    }
  ),
  createPartner(
    'enel',
    'Enel',
    true,
    {},
    {
      discount: null,
    }
  ),
  createPartner(
    'banco-original',
    'Banco Original',
    true,
    {},
    {
      discount: null,
    }
  ),
  createPartner(
    'inter',
    'Banco Inter',
    true,
    {},
    {
      discount: 90,
    }
  ),
  createPartner(
    'neon',
    'Neon',
    true,
    {},
    {
      discount: 90,
    }
  ),
  createPartner(
    'safra',
    'Banco Safra',
    false,
    {},
    {
      discount: null,
    }
  ),
  createPartner(
    'cea-pay',
    'C&A',
    false,
    {},
    {
      discount: null,
    }
  ),
  createPartner(
    'btg',
    'Banco BTG',
    true,
    {},
    {
      discount: 90,
    }
  ),
]

const getByKey = (array: typeof PARTNERS) => {
  return array.reduce((acc, cur) => {
    // @ts-expect-error
    acc[cur.partnerKey] = cur
    return acc
  }, {})
}

const PARTNER_LIST_SEQUENCE = [
  'vivo',
  'bradesco',
  'claro-net',
  'recovery',
  'nubank',
  'oi',
  'santander',
  'hoepers',
  'itapeva',
  'ativos',
  'riachuelo',
  'itau',
  'bb',
  'ipanema',
  'credsystem',
  'carrefour',
  'avon',
  'crediativos',
  'havan',
  'nextel',
  'sky',
  'bmg',
  'c6',
  'crefisa',
  'porto-seguro',
  'marisa',
  'renner',
  'tribanco',
  'cbss',
  'pernambucanas',
  'algar',
  'dmcard',
  'tim',
  'dscard',
  'losango',
  'bv',
  'zema',
  'boticario',
  'willbank-pag',
  'senff',
  'pan',
  'enel',
  'banco-original',
  'inter',
  'neon',
  'safra',
  'cea-pay',
  'btg',
]

const PARTNERS = items.map((item, index) => ({
  ...item,
  id: index,
  image: {
    '1x': `${HOST_IMAGES}/${item.partnerKey}@1x.png`,
    '2x': `${HOST_IMAGES}/${item.partnerKey}@2x.png`,
  },
}))

export const PARTNERS_LIST_MAP = PARTNER_LIST_SEQUENCE.map((partnerKey) => {
  // @ts-expect-error
  const partner = getByKey(PARTNERS)[partnerKey]

  const {
    partners: { customBlacklabel },
  } = partner

  const newPartner = {
    ...partner,
    blacklabelUrl: customBlacklabel || partner.partnerKey,
  }

  return newPartner
})

export default PARTNERS
