import clsx from 'clsx'

import { AnchorFooter } from '@lno/core/components/AnchorFooter'
import { Navbar } from '@lno/core/components/Navbar'
import { useCampaign } from '@lno/core/hooks/useCampaign'
import { Content } from '@lno/screens/Home/components/Content'
import { HeroSection } from '@lno/screens/Home/components/HeroSection'

export type WebTestProps = {
  web?: boolean
}

function HomeLayout({ web }: WebTestProps) {
  const { isActiveCampaign } = useCampaign()
  return (
    <>
      <Navbar signInEnabled={false} web={web} />

      <main
        className={clsx({
          'colors-campaign': isActiveCampaign,
        })}
      >
        <HeroSection web={web} />
        <Content web={web} />
        <AnchorFooter />
      </main>
    </>
  )
}

export default HomeLayout
