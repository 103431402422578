import clsx from 'clsx'

import { Typography } from '@lno/components/Typography'

import { useCampaign } from '@lno/core/hooks/useCampaign'
import StepList from './StepList'
import { styles } from './styles'
import type { HowWorksSectionProps } from './types'

const jsxScope = `jsx-${styles.__hash}`

export const HowWorksSection = ({
  boxComponent: BoxComponent,
  web,
}: HowWorksSectionProps) => {
  const { isActiveCampaign } = useCampaign()
  return (
    <section
      id="howworks"
      className={clsx('how-works-section', {
        '--box-cta-active': !!BoxComponent,
      })}
    >
      {BoxComponent && (
        <BoxComponent
          id="cpf-input-middle"
          customText="Aproveite ofertas exclusivas para quitar suas dívidas"
          className={clsx(jsxScope, 'how-works-section__box-cpf')}
          web={web}
        />
      )}
      <div
        className={clsx('how-works-section__container', {
          '--box-cta-active': !!BoxComponent,
        })}
      >
        <div className="how-works-section__steps-wrapper">
          <Typography
            tag="h2"
            size="headingM"
            color="high"
            className={clsx(jsxScope, 'how-works-section__title')}
          >
            {!isActiveCampaign
              ? 'Como funciona o Serasa Limpa Nome?'
              : 'Como fazer acordos e negociar dívidas no MegaFeirão Serasa Limpa Nome?'}
          </Typography>

          <StepList />
        </div>
      </div>

      <style jsx>{styles}</style>
    </section>
  )
}

export default HowWorksSection
