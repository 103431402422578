import { breakpoints } from '@lno/core/constants'
import css from 'styled-jsx/css'

export const styles: any = css`
  .partner__title {
    font-size: 2rem;
    text-align: center;
    line-height: 2.25rem;
    letter-spacing: -0.05rem;
    margin: 2rem 0 1rem;
    @media ${breakpoints.desktopDown} {
      font-size: 1.5rem;
      line-height: 1.75rem;
      margin: 1rem 0;
    }
  }
  .partner__subtitle {
    font-size: 1rem;
    text-align: center;
    @media ${breakpoints.tabletDown} {
      inline-size: 90%;
    }
  }
  .partner__title.--others,
  .partner__subtitle {
    color: var(--dark-medium, rgba(0, 8, 38, 1));
  }

  .partner-card__picture {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    max-width: 16rem;
    min-width: 5rem;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  .discount__description {
    display: flex;
  }
  .card .partner-card__divider {
    display: flex;
    width: 100%;
    @media ${breakpoints.desktop} {
      justify-content: center;
    }
  }

  .partner-card__divider {
    grid-area: logo;
  }
  .partner-card__divider-discount {
    grid-area: discount;
  }
  .partner-card__divider-button {
    grid-area: cta;
  }

  .card,
  .card_more {
    display: grid;
    font-weight: bold;
    border-radius: 1rem;
    overflow: hidden;

    @media ${breakpoints.desktopDown} {
      grid-template-rows: 60% 40%;
      grid-template-columns: 45% 55%;
      grid-template-areas:
        'logo discount'
        'cta cta ';
      height: 10rem;
      padding: 1rem;

      .partner-card__picture {
        justify-content: flex-start;
      }
      .discount__text {
        font-size: 1rem;
        text-align: right;
      }
      .discount__percentage {
        letter-spacing: -0.15rem;
      }
      .partner-card__divider-button {
        display: flex;
        align-items: end;
      }
      .rounded-tag {
        height: 2.25rem;
        max-width: 100%;
      }
      .logo {
        max-width: 8rem;
        max-height: 4rem;
      }
    }
  }

  .cards-wrapper {
    display: grid;
    width: 100%;
    gap: 1rem;
  }

  .cards-wrapper.--gold {
    @media ${breakpoints.desktop} {
      grid-template-columns: repeat(2, 1fr);
    }
    @media ${breakpoints} {
      grid-template-columns: repeat(2, 1fr);
    }

    .partner-card__container.--gold {
      display: flex;
      flex-direction: column;

      .card {
        @media ${breakpoints.desktop} {
          grid-template-rows: 50% 20% 30%;
          grid-template-columns: 90%;
          grid-template-areas:
            'logo '
            'discount '
            'cta';
          height: 24rem;

          .partner-card__divider-button {
            display: flex;
            width: 100%;
            align-items: center;
            height: 100%;
          }
          .discount__description {
            justify-content: center;
          }
          .rounded-tag {
            max-width: 100%;
          }
        }
      }
    }
  }
  .cards-wrapper-more {
    @media ${breakpoints.desktop} {
      display: flex !important;
      flex-wrap: wrap;

      .partner-card__container {
        width: 100%;
      }
      .card_more {
        height: 9.5rem;
        gap: 1.5rem;
        grid-template-columns: 35% 25% 35%;
        grid-template-areas: 'discount logo cta';
        padding: 1.75rem 2rem;

        .partner-card__divider {
          display: flex;
          justify-content: flex-start;
        }
        .partner-card__divider-button {
          display: flex;
          justify-content: end;
        }
        .logo {
          max-width: 16rem;
          max-height: 8rem;
        }
      }
    }
  }

  .partner-card__container.--gold {
    .discount__text {
      text-align: right;
      font-weight: 600;
    }
    .discount__description,
    .discount__text,
    .discount__percentage {
      color: var(--fonts-snow);
    }
    .discount__percentage {
      font-weight: 900;
    }
    .rounded-tag {
      background-color: var(--fonts-snow);
      color: var(--theme-primary);
    }
    .card,
    .card_more {
      background-color: rgb(111, 41, 97);
      align-items: center;
      justify-content: center;
    }
    @media ${breakpoints.desktopDown} {
      .discount__percentage {
        font-size: 3rem;
      }
      .discount__description {
        display: flex;
        flex-direction: column;
        align-items: end;
      }
    }

    @media ${breakpoints.desktop} {
      .discount__text {
        font-size: 1.5rem;
      }
      .discount__percentage {
        font-size: 6rem;
        letter-spacing: -0.36rem;
      }
      .discount__text {
        width: 7rem;
      }
      .discount__description {
        gap: 1.5rem;
        align-items: center;
      }
    }
  }

  .cards-wrapper.--silver {
    @media ${breakpoints.desktopDown} {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
    @media ${breakpoints.desktop} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .partner-card__container.--silver {
    .card {
      height: 10.5rem;
    }
    @media ${breakpoints.desktop} {
    }
    @media ${breakpoints.desktopDown} {
      .discount__percentage {
        font-size: 2.5rem;
      }
    }
  }

  .cards-wrapper.--bronze {
    @media ${breakpoints.desktopDown} {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
    @media ${breakpoints.desktop} {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .partner-card__container.--bronze {
    .card {
      height: 10rem;
    }
    @media ${breakpoints.desktopDown} {
      .discount__percentage {
        font-size: 2rem;
      }
    }
  }
  .cards-wrapper.--others {
    @media ${breakpoints.desktop} {
      grid-template-columns: repeat(4, 1fr);
    }
    @media ${breakpoints.desktopDown} {
      .discount__percentage {
        font-size: 1.5rem;
      }
    }
  }
  .partner__title.--others {
    color: var(--fonts-dark-high);
    margin-top: 6rem;
    font-weight: bold;
    margin: 2rem 0 1rem;
  }

  .partner-card__container.--others {
    .card {
      .discount__percentage {
        font-size: 1.5rem; //mudei
      }
      @media ${breakpoints.desktop} {
        height: 13rem;
        grid-template-rows: 45% 25% 30%;
        grid-template-areas:
          'logo'
          'discount'
          'cta';

        .discount__description {
          justify-content: center;
        }
        .discount__percentage {
          letter-spacing: -0.09rem;
        }
        .partner-card__divider-discount {
          align-self: end;
        }
      }
      @media ${breakpoints.desktopDown} {
        .partner-card__divider-discount {
          align-self: center;
        }
        .discount__description {
          justify-content: end;
          align-items: end;
        }
        .discount__description {
          gap: 0.2rem;
        }
      }
    }
  }

  .partner-card__container.--bronze,
  .partner-card__container.--silver {
    .discount__description {
      flex-direction: column;
    }
    .discount__percentage {
      line-height: 3rem;
    }
    @media ${breakpoints.desktopDown} {
      .discount__description {
        flex-direction: column;
        align-items: end;
      }
    }
    .card {
      @media ${breakpoints.desktop} {
        grid-template-columns: 50% 50%;
        grid-template-areas:
          'logo discount'
          'logo cta';

        .discount__percentage {
          font-size: 3rem;
          letter-spacing: -0.18rem;
        }
      }
    }
  }

  .partner-card__container.--bronze,
  .partner-card__container.--silver,
  .partner-card__container.--others {
    border-radius: 0.625rem;
    border: 0.063rem solid var(--silver, #d4dae0);

    .discount__text {
      color: rgba(0, 0, 0, 0.8);
      font-size: 0.875rem;
      line-height: 1.25rem;
    }

    .discount__percentage {
      color: #000;
    }
    .rounded-tag {
      height: 2.25rem;
      background-color: var(--theme-primary);
      color: var(--fonts-snow);
      font-size: 0.875rem;
    }

    .card {
      @media ${breakpoints.desktop} {
        padding: 1rem;

        .discount__text {
          font-weight: 500;
        }

        .discount__description {
          align-items: center;
          gap: 0.5rem;
        }
        .partner-card__divider-button {
          display: flex;
          align-items: end;
          justify-content: center;
        }
        .logo {
          max-width: 8rem;
          max-height: 5.5rem;
        }
      }
    }
  }

  li {
    list-style-type: none;
  }
`
