import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .grid {
    margin: 0 auto;
    padding: 0 0.5rem;
  }

  @media ${breakpoints.tablet} {
    .grid {
      padding: 0 1rem;
    }
  }

  @media ${breakpoints.desktop} {
    .grid {
      padding: 0 1.5rem;
      max-width: 85rem;
    }
  }
`
