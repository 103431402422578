import clsx from 'clsx'

import { Grid } from '@lno/components/Grid'
import { Typography } from '@lno/components/Typography'

import { FeedbackItem } from './FeedbackItem'

import { FEEDBACKS } from './constants'

import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

export const FeedbacksSection = () => (
  <section className="feedbacks-section">
    <Grid className={clsx(jsxScope, 'feedbacks-section__container')}>
      <Typography
        size="headingM"
        color="high"
        className={clsx(jsxScope, 'feedbacks-section__title')}
        tag="h2"
      >
        Depoimentos de quem já limpou o nome
      </Typography>
      <ol className="feedbacks-section__list">
        {FEEDBACKS.map((item, index) => (
          <FeedbackItem key={index} feedback={item} />
        ))}
      </ol>
    </Grid>

    <style jsx>{styles}</style>
  </section>
)

export default FeedbacksSection
