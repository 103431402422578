import { useCmsHero } from '@lno/core/hooks/useCmsHero'
import { WebTestProps } from '@lno/screens/Home/layouts'
import { CmsHero } from './CmsHero'
import { DefaultHero } from './DefaultHero'

export function Hero({ web }: WebTestProps) {
  const { hero, hasCmsHeroData } = useCmsHero()
  if (!hasCmsHeroData) return <DefaultHero web={web} />
  return <CmsHero props={hero!} />
}
