import css from 'styled-jsx/css'

export const styles: any = css`
  .advertising-wrapper {
    width: 100%;
    min-height: 280px;
    margin: 2rem 0;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .advertising {
    background-color: #f6f8fa;
    max-width: 960px;
    margin: auto;
    border-radius: 0.625rem;
    padding: 0.5rem 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .advertising::before {
    content: 'Publicidade';
    display: flex;
    justify-content: center;
    width: min-content;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    opacity: 0.33;
    font-size: 0.75rem;
  }

  .advertising--fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .advertising--hidden {
    display: none;
  }
`
