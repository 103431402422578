import clsx from 'clsx'

import { styles } from './styles'

import ButtonLink from '@lno/core/components/ButtonLink'
import { Hero } from '@lno/core/models/Hero'
import { GASelectContentEvent } from '@lno/core/modules/analytics/events'
import { useEffect, useState } from 'react'
import { DesenrolaBrasil } from './DesenrolaBrasil'
import { utils } from './utils'
const jsxScope = `jsx-${styles.__hash}`

interface Props {
  className?: string
  data: Hero['desenrolaSection']
}

export function GovernmentDebtNegotiationProgramSection({
  data,
  className,
}: Props) {
  const [sanitizedContent, setSanitizedContent] = useState<string | null>(null)
  const content = data?.content?.text

  useEffect(() => {
    if (!content) return
    setSanitizedContent(utils.getSanitizedContent(content))
  }, [content])

  const hasContent = !!data?.title?.text && !!sanitizedContent
  if (!data || !hasContent) return null

  const handleEvent = () =>
    GASelectContentEvent.consultCnpjEvent(data.cta?.label)

  return (
    <section className={clsx('debt-negotiation-program-section', className)}>
      <div
        className={clsx(
          'debt-negotiation-program-section__content-wrapper',
          className
        )}
      >
        <DesenrolaBrasil.Title title={data.title} />
        <DesenrolaBrasil.Image images={data.image} />
        <DesenrolaBrasil.Description content={data.content} />
        {data.cta && (
          <ButtonLink
            href={data.cta.url}
            color="primary"
            onClick={handleEvent}
            className={clsx(
              jsxScope,
              'debt-negotiation-program-section__button'
            )}
          >
            {data.cta.label}
          </ButtonLink>
        )}
      </div>
      <style jsx>{styles}</style>
    </section>
  )
}

GovernmentDebtNegotiationProgramSection.defaultProps = {}

export default GovernmentDebtNegotiationProgramSection
