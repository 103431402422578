import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .feedbacks-section {
    padding: 2rem 1.5rem;
    background-color: #f5f7f9;
    text-align: center;
  }

  .feedbacks-section__title {
    padding: 0 1rem;
    margin-bottom: 0.5rem;
  }

  .feedbacks-section__list {
    list-style: none;
  }

  @media ${breakpoints.tablet} {
    .feedbacks-section__list {
      display: flex;
    }
  }

  @media ${breakpoints.desktop} {
    .feedbacks-section__title {
      text-align: left;
      max-width: 23rem;
      margin-right: 8rem;
    }

    .feedbacks-section__container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      max-width: 75rem;
    }
  }
`
