import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .cross-ecred {
    padding: 2rem 1rem;
    text-align: center;
  }

  .cross-ecred__content {
    display: flex;
    margin: 2rem 0;
    text-align: left;
  }

  .cross-ecred__logo {
    width: 6rem;
    margin-right: 1rem;
  }

  .cross-ecred__description {
    max-width: 22rem;
  }

  .cross-ecred__link {
    max-width: 22.875rem;
    width: 100%;
    margin: 0 auto;
  }

  @media ${breakpoints.tablet} {
    .cross-ecred__content {
      align-items: center;
      justify-content: center;
      max-width: 75rem;
    }

    .cross-ecred__logo {
      width: 10rem;
      margin-right: 2rem;
    }
  }

  @media ${breakpoints.desktop} {
    .cross-ecred__content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
