import Typography from '@lno/core/components/Typography'
import clsx from 'clsx'
import { smallerFontSizeStyles, styles } from '../styles'

const jsxScope = `jsx-${styles.__hash}`

interface HeaderProps {
  title: string
  subtitle: string
}

export const Header = ({ title, subtitle }: HeaderProps) => (
  <>
    <Typography
      size="headingL"
      tag="h1"
      color="snow"
      weight="bold"
      className={clsx(jsxScope, 'cms-hero__title')}
      dangerouslySetInnerHTML={{ __html: title }}
    />
    <div className="cms-hero__description">
      <Typography size="bodyM" tag="p" color="snow" className={clsx(jsxScope)}>
        {subtitle}
      </Typography>
    </div>
    <style jsx>{styles}</style>
    <style jsx global>
      {smallerFontSizeStyles}
    </style>
  </>
)
