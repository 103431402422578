import { breakpoints } from '@lno/core/constants'
import css from 'styled-jsx/css'

export const styles: any = css`
  .escape-the-blow-section {
    padding: 0 1.5rem 2rem 1.5rem;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  .escape-the-blow-section__container {
    max-width: var(--container-size);
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    row-gap: 1.5rem;
    padding-top: 2rem;
    &.--box-cta-active {
      margin-top: -6rem;
      padding-top: 0;
    }
  }

  .escape-the-blow-section__content {
    max-width: 57.625rem;
    width: 100%;
    row-gap: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  .escape-the-blow-section__steps {
    list-style-type: none;
    &__item {
      counter-increment: section;
      & :global(a) {
        color: var(--fonts-accent);
        text-decoration: none;
      }
      &:before {
        content: counter(section) ') ';
        font-weight: bold;
      }
      &:not(:first-child) {
        margin-top: 1.5em;
      }
    }
  }

  .escape-the-blow-section__box-cpf {
    position: relative;
    top: -6rem;
  }

  .escape-the-blow-section__container__image {
    margin: 0 auto;
    margin-top: 3.75rem;
  }

  @media ${breakpoints.desktop} {
    .escape-the-blow-section__container__image {
      margin-right: 4rem;
      margin-top: 0;
    }
  }

  @media ${breakpoints.tablet} {
    .escape-the-blow-section__container {
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      &.--box-cta-active {
        margin-top: 1rem;
      }
    }
  }
`
