import breakpoints from '@lno/core/constants/breakpoints'
import css from 'styled-jsx/css'

export const smallerFontSizeStyles = css.global`
  small {
    font-size: 1rem;
  }
  @media ${breakpoints.tablet} {
    small {
      font-size: 1.5rem;
    }
  }
`

export const styles: any = css`
  .cms-hero {
    background-color: var(--theme-primary);
    padding-top: 4.125rem;
  }
  .cms-hero__disclaimer {
    background-color: #fcedf4;
    padding: 1rem 1.5rem;
    &__text {
      font-size: 0.875rem;
      max-width: calc(var(--container-size) + 4rem);
      padding: 0.25rem 2rem;
      margin: auto;

      @media ${breakpoints.bigDesktopDown} {
        padding: 0.5rem 0;
      }
    }
  }

  .cms-hero__container {
    display: grid;
    position: relative;
  }

  .cms-hero__title {
    padding: 0 1.5rem;
    font-size: 2rem;
    max-width: 40rem;
    margin: 0 0 1rem;
  }

  .cms-hero__description {
    padding: 0 1.5rem;
    text-align: left;
    max-width: 59rem;
    margin-bottom: 0.5rem;

    & p {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }

  .cms-hero__picture {
    display: flex;
    justify-content: center;

    &__box {
      height: 100%;

      max-height: 14rem;
    }
  }

  @media ${breakpoints.tabletDown} {
    .cms-hero__container.--countdown {
      .cms-hero__picture__box {
        max-height: 8rem;
        max-width: 8.5rem;
      }
    }
    .cms-hero__picture__box {
      max-width: 14rem;
    }
  }

  .cms-hero .cms-hero__img {
    width: 100%;
    object-fit: scale-down;
    &--default {
      height: 100%;
    }
  }

  .cms-hero__cta-wrapper {
    display: flex;
    justify-content: center;
  }

  .cms-hero__arguments {
    margin: auto;

    &__wrapper {
      padding: 1rem 1.5rem;
      display: flex;
      justify-content: center;
      flex-direction: row;
      background: linear-gradient(
        184deg,
        rgba(200, 24, 105, 1) 0%,
        rgba(147, 0, 58, 1) 100%
      );
      gap: 1rem;

      &__divider {
        border: none;
        height: 6.5rem;
        width: 2px;
        background-color: var(--fonts-snow);

        &:last-of-type {
          display: none;
          visibility: hidden;
          opacity: 0;
        }
      }
    }
    &__payment-details {
      max-width: 5rem;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  .cms-hero__schedules {
    margin: 0;
    padding-left: 1.5rem;
  }

  @media ${breakpoints.desktopDown} {
    .cms-hero__cta-wrapper__box {
      display: flex;
      justify-content: center;
      background-color: var(--fonts-snow);
      border-radius: 0.625rem;
      box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.25);
      margin-bottom: 1.5rem;

      & a {
        margin: 1rem;
        background-color: var(--theme-primary);
        color: var(--fonts-snow);
        min-width: 18.5rem;
      }
    }
  }

  @media ${breakpoints.tablet} {
    .cms-hero__arguments {
      &__wrapper {
        gap: 3rem;
        &__divider {
          width: 3px;
          height: 5.75rem;
        }
      }
      &__payment-details {
        max-width: 12rem;
        gap: 0.25rem;
        & span {
          font-size: 1rem;
        }
        & img {
          max-height: 65px;
          width: fit-content;
          max-height: 5rem;
        }
      }
    }
    .cms-hero__picture__box {
      max-height: 25rem;
      max-width: 21.5rem;
    }
  }

  @media ${breakpoints.tabletDown} {
    .cms-hero__cta-wrapper__box {
      & a {
        min-width: 17rem;
      }
    }
  }

  @media ${breakpoints.desktop} {
    .cms-hero__schedules {
      margin: 0;
    }

    .cms-hero {
      padding-top: 6.25rem;

      &__container {
        max-width: calc(var(--container-size) + 4rem);
        padding: 0 2rem;
        margin: 0 auto;
        grid-template-rows: 2rem auto auto auto auto auto 2rem;
      }
      &__container.--countdown {
        grid-template-rows: 2rem auto auto auto auto 2rem;
      }

      &__title {
        padding: 0;
        font-size: 3rem;
        line-height: 3.5rem;
        margin: 0;
        grid-row: 2;
      }

      &__container.--countdown &__title {
        grid-column: 1;
      }

      &__description {
        padding: 0;
        grid-row: 3;
        grid-column: 1;
        margin: 1rem 0 0;
        max-width: 32.5rem;
      }

      &__schedules {
        grid-row: 4;
        grid-column: 1;
        padding: 0;
      }

      &__picture {
        &--default {
          grid-row: 2 / span 6;
          min-height: 25rem;
        }
        &__box {
          max-height: 25rem;
          max-width: 24.5rem;
        }
      }

      &__img {
        max-width: 29.375rem;
        width: auto;
      }

      &__cta-wrapper {
        justify-content: start;
        grid-column: 1;
        grid-row: 5;
        margin: 0;

        &__box a {
          width: 19.5rem;
        }
      }

      &__cta {
        background-color: var(--fonts-snow);
        width: 19.5rem;
      }
    }

    .cms-hero__arguments {
      &__wrapper {
        gap: 5rem;
      }
    }
  }
`
