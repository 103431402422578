/* eslint-disable no-use-before-define */

export type SubscriptionCallback = (now: number) => void

export type Subscription = { unsubscribe: () => void }

export type AccurateInterval = {
  unsubscribe(fn: SubscriptionCallback): void
  subscribe: (fn: SubscriptionCallback) => Subscription
}

export function accurateInterval(ms: number): AccurateInterval {
  const start = document.timeline
    ? document.timeline.currentTime
    : performance.now()
  const startTimestamp = Date.now()
  const subscriptions = new Set<SubscriptionCallback>()
  let aborted = true

  function frame(time: number) {
    if (aborted) return

    const now = startTimestamp + time - start!

    subscriptions.forEach((cb) => cb(now))
    scheduleFrame(time)
  }

  function scheduleFrame(time: number) {
    const elapsed = time - start!
    const roundedElapsed = Math.round(elapsed / ms) * ms
    const targetNext = start! + roundedElapsed + ms
    const delay = targetNext - performance.now()

    setTimeout(() => requestAnimationFrame(frame), delay)
  }

  function subscribe(fn: SubscriptionCallback) {
    subscriptions.add(fn)

    if (aborted) {
      aborted = false
      scheduleFrame(start!)
    }

    return {
      unsubscribe: () => unsubscribe(fn),
    }
  }

  function unsubscribe(fn: SubscriptionCallback) {
    subscriptions.delete(fn)

    if (!subscriptions.size) aborted = true
  }

  return {
    unsubscribe,
    subscribe,
  }
}
