import { useContext } from 'react'

import { AdvertisingContext } from '../providers'

export function useAdvertising() {
  const context = useContext(AdvertisingContext)

  if (!context) throw new Error('AdvertisingProvider not found.')

  return context
}

export default useAdvertising
