/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'

import { Typography } from '@lno/core/components/Typography'

import { styles } from '../styles'

import heroMobileImg from '@assets/images/hero-campaign/hero-mobile.png'
import heroMobileImgWebp from '@assets/images/hero-campaign/hero-mobile.webp'

import heroDeskImg from '@assets/images/hero-campaign/hero-desktop.png'
import heroDeskImgWebp from '@assets/images/hero-campaign/hero-desktop.webp'

import heroInterestFreeMobileImg from '@assets/images/hero-interest-free/hero-mobile.png'
import heroInterestFreeMobileImgWebp from '@assets/images/hero-interest-free/hero-mobile.webp'

import heroInterestFreeDeskImg from '@assets/images/hero-interest-free/hero-desktop.png'
import heroInterestFreeDeskImgWebp from '@assets/images/hero-interest-free/hero-desktop.webp'

import heroInterestFreeExtendedMobileImg from '@assets/images/hero-interest-free-extended/hero-mobile.png'
import heroInterestFreeExtendedMobileImgWebp from '@assets/images/hero-interest-free-extended/hero-mobile.webp'

import heroInterestFreeExtendedDeskImg from '@assets/images/hero-interest-free-extended/hero-desktop.png'
import heroInterestFreeExtendedDeskImgWebp from '@assets/images/hero-interest-free-extended/hero-desktop.webp'

import BoxCtaLogin from '@lno/core/components/BoxCtaLogin'
import { useCampaignTimer } from '@lno/core/hooks/useCampaignTimer'
import { WebTestProps } from '@lno/screens/Home/layouts'
import { useRef } from 'react'
import { InterestFreeCronometer } from '../../../InterestFreeCronometer'
import {
  FIRST_DAY_EXTENDED_INTEREST_FREE_CAMPAIGN,
  FIRST_DAY_INTEREST_FREE_CAMPAIGN,
  FIRST_DAY_INTEREST_FREE_CAMPAIGN_START_STOPWATCH,
  LAST_DAY_EXTENDED_INTEREST_FREE_CAMPAIGN,
  LAST_DAY_INTEREST_FREE_CAMPAIGN,
} from '../constants'
import { useHeroEvent } from '../useHeroEvent'
const jsxScope = `jsx-${styles.__hash}`

export function DefaultHero({ web }: WebTestProps) {
  const ref = useRef<HTMLElement>(null)
  const interestFreeCampaignData = useCampaignTimer({
    endTimeCampaign: LAST_DAY_INTEREST_FREE_CAMPAIGN,
    startStopWatch: FIRST_DAY_INTEREST_FREE_CAMPAIGN_START_STOPWATCH,
    startTimeCampaign: FIRST_DAY_INTEREST_FREE_CAMPAIGN,
  })

  const extendedInterestFreeCampaignData = useCampaignTimer({
    endTimeCampaign: LAST_DAY_EXTENDED_INTEREST_FREE_CAMPAIGN,
    startStopWatch: FIRST_DAY_EXTENDED_INTEREST_FREE_CAMPAIGN,
    startTimeCampaign: FIRST_DAY_EXTENDED_INTEREST_FREE_CAMPAIGN,
  })

  const campaignDefaultActive =
    !interestFreeCampaignData.campaignIsActive &&
    !extendedInterestFreeCampaignData.campaignIsActive

  const title = interestFreeCampaignData.stopWatchCampignIsActive
    ? interestFreeCampaignData.title
    : 'Negociação de dívidas parceladas em até 24x sem juros.'

  useHeroEvent({ label: title, ref })

  return (
    <>
      <section className="hero" ref={ref}>
        <div className="hero__container">
          {interestFreeCampaignData.campaignIsActive && (
            <>
              <Typography
                size="headingL"
                tag="h1"
                color="snow"
                weight="bold"
                className={clsx(jsxScope, 'hero__title')}
              >
                {title}
              </Typography>
              {interestFreeCampaignData.stopWatchCampignIsActive ? (
                <div className="hero__schedules">
                  <InterestFreeCronometer
                    schedules={interestFreeCampaignData.schedules!}
                    description={interestFreeCampaignData.description}
                  />
                </div>
              ) : (
                <Typography
                  size="headingS"
                  tag="p"
                  color="snow"
                  weight="bold"
                  className={clsx(jsxScope, 'hero__description')}
                >
                  Só na Serasa! Descubra agora se possui uma oferta
                  <br />
                  disponível e aproveite.
                </Typography>
              )}

              <div className="hero__picture hero__picture__campaign">
                <picture>
                  <source
                    srcSet={heroInterestFreeMobileImgWebp}
                    media="(max-width: 599px)"
                    type="image/webp"
                  />
                  <source
                    srcSet={heroInterestFreeDeskImgWebp}
                    media="(min-width: 600px)"
                    type="image/webp"
                  />

                  <source
                    srcSet={heroInterestFreeMobileImg}
                    media="(max-width: 599px)"
                  />
                  <source
                    srcSet={heroInterestFreeDeskImg}
                    media="(min-width: 600px)"
                  />
                  <img
                    className="hero__img"
                    src={heroInterestFreeMobileImg}
                    alt="Do lado esquerdo, um homem jovem pardo de blusa azul com preto. Do lado direito, um texto informando S de Sem Juros nas parcelas."
                    width={400}
                    height={325}
                  />
                </picture>
              </div>
            </>
          )}

          {extendedInterestFreeCampaignData.campaignIsActive && (
            <>
              <Typography
                size="headingL"
                tag="h1"
                color="snow"
                weight="bold"
                className={clsx(jsxScope, 'hero__title')}
              >
                {extendedInterestFreeCampaignData.title}
              </Typography>
              <div className="hero__schedules">
                <InterestFreeCronometer
                  schedules={extendedInterestFreeCampaignData.schedules!}
                  description={extendedInterestFreeCampaignData.description}
                />
              </div>

              <div className="hero__picture hero__picture__campaign">
                <picture>
                  <source
                    srcSet={heroInterestFreeExtendedMobileImgWebp}
                    media="(max-width: 599px)"
                    type="image/webp"
                  />
                  <source
                    srcSet={heroInterestFreeExtendedDeskImgWebp}
                    media="(min-width: 600px)"
                    type="image/webp"
                  />

                  <source
                    srcSet={heroInterestFreeExtendedMobileImg}
                    media="(max-width: 599px)"
                  />
                  <source
                    srcSet={heroInterestFreeExtendedDeskImg}
                    media="(min-width: 600px)"
                  />
                  <img
                    className="hero__img"
                    src={heroInterestFreeExtendedMobileImg}
                    alt="Do lado esquerdo, um senhor de barba, com óculos e boina, vestido com uma roupa azul e marrom segurando um celular com o aplicativo do Serasa. Do lado direito, está escrito S de Sem Juros nas parcelas prorrogado."
                    width={400}
                    height={325}
                  />
                </picture>
              </div>
            </>
          )}

          {/* Without both campaigns deactivated, it reverts to the default campaign. */}
          {campaignDefaultActive && (
            <>
              <Typography
                size="headingL"
                tag="h1"
                color="snow"
                weight="bold"
                className={clsx(jsxScope, 'hero__title')}
              >
                Negociar dívidas com até 90% de desconto e muito mais?
              </Typography>

              <div className="hero__description">
                <Typography size="headingS" tag="h2" color="snow" weight="bold">
                  Só na Serasa. Pague com o Pix e tenha o nome limpo na hora*
                </Typography>
              </div>

              <div className="hero__picture hero__picture--default">
                <picture className="hero__picture__box">
                  <source
                    srcSet={heroMobileImgWebp}
                    type="image/webp"
                    media="(max-width: 599px)"
                  />
                  <source
                    srcSet={heroDeskImgWebp}
                    type="image/webp"
                    media="(min-width: 600px)"
                  />

                  <source srcSet={heroMobileImg} media="(max-width: 599px)" />
                  <source srcSet={heroDeskImg} media="(min-width: 600px)" />
                  <img
                    className="hero__img hero__img--default"
                    src={heroMobileImg}
                    alt="Homem branco, de óculos com barba, com terno preto e gravata azul, segurando o celular com o aplicativo da Serasa."
                    width={400}
                    height={325}
                  />
                </picture>
              </div>
            </>
          )}

          <div className="hero__cta-wrapper">
            <BoxCtaLogin
              className={clsx(jsxScope, 'hero__cta')}
              web={web}
              customText={
                campaignDefaultActive
                  ? 'Consulte grátis as ofertas disponíveis para você.'
                  : ''
              }
            />
          </div>
        </div>
        <style jsx>{styles}</style>
      </section>
      <div className={clsx(jsxScope, 'hero__disclaimer--grid')}>
        <Typography
          tag="p"
          color="medium"
          size="bodyP"
          className={clsx(jsxScope, 'hero__disclaimer')}
        >
          <span aria-hidden>*</span>Ofertas de desconto disponíveis conforme
          condições da empresa credora.
        </Typography>
      </div>
    </>
  )
}
