import clsx from 'clsx'

import { Typography } from '@lno/components/Typography'

import { styles } from './styles'
import { VisuallyHidden } from '@lno/core/components/VisuallyHidden'

const jsxScope = `jsx-${styles.__hash}`

type FeedbacksSectionProps = {
  feedback: {
    author: string
    description: string
  }
}

export const FeedbackItem = ({ feedback }: FeedbacksSectionProps) => (
  <li className={clsx(jsxScope, 'feedback-item')}>
    <Typography
      size="bodyP"
      className={clsx(jsxScope, 'feedback-item__ballon')}
      tag="p"
    >
      <VisuallyHidden>{`Depoimento de ${feedback.author}: `}</VisuallyHidden>
      {feedback.description}
    </Typography>
    <Typography size="bodyM" tag="p" color="medium" weight="bold" aria-hidden>
      {feedback.author}
    </Typography>

    <style jsx>{styles}</style>
  </li>
)

export default FeedbackItem
