import { ONE_HOUR_TIMESTAMP } from '@lno/core/constants/time'
import { TITLE_DEFAULT } from '../constants'

export const getTitle = (diffDates: number) => {
  const hours = Math.floor(diffDates / ONE_HOUR_TIMESTAMP)

  if (hours >= 72) return `${TITLE_DEFAULT} Últimos dias!`

  if (hours < 72 && hours >= 24) return `${TITLE_DEFAULT} Últimas horas!`

  return `${TITLE_DEFAULT} Está acabando!`
}
