import { scopedClsx } from '@lno/core/utils/styled'

import type { PartnersProps } from './core/types'
import { CardsTitle } from './core/CardsTitle'
import { PartnerTypes } from './core/constants'
import { PartnerCard } from './PartnerCard'
import { styles } from './styles'

const cl = scopedClsx(styles)

export function Partners({
  partners,
  label,
  subtitle,
  showTitle,
  type,
}: PartnersProps) {
  const classNameTypes = cl({
    '--gold': type === PartnerTypes.Gold,
    '--silver': type === PartnerTypes.Silver,
    '--bronze': type === PartnerTypes.Bronze,
    '--others': type === PartnerTypes.Others,
  })

  const hasOnlyOneSilverPartner =
    type === PartnerTypes.Silver && partners.length === 1

  const hasMoreTwoGoldPartner =
    type === PartnerTypes.Gold && partners.length > 2

  return (
    <>
      <CardsTitle
        showTitle={showTitle}
        subtitle={subtitle}
        label={label}
        className={cl(classNameTypes)}
      />
      <ol
        className={cl('cards-wrapper', classNameTypes, {
          'cards-wrapper-more': hasMoreTwoGoldPartner,
        })}
      >
        {partners.map((partner, index) => (
          <li
            key={`partner-card_${type}_${index}`}
            className={cl('partner-card__container', classNameTypes, {
              '--fullWidth': hasOnlyOneSilverPartner,
            })}
          >
            <PartnerCard
              partner={partner}
              hasMoreTwoGoldPartner={hasMoreTwoGoldPartner}
              type={type}
            />
          </li>
        ))}

        <style jsx>{styles}</style>
      </ol>
    </>
  )
}
