import css from 'styled-jsx/css'

import { breakpoints } from '@lno/core/constants'

export const styles: any = css`
  .partner-list {
    max-width: 30rem;
    margin: 0 auto;
    padding: 0.5rem;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
    list-style: none;
  }

  .partner-list--hidden-mobile {
    display: none !important;
  }

  @media ${breakpoints.desktop} {
    .partner-list {
      max-width: 55rem;
      justify-content: center;
    }

    .partner-list--hidden-mobile {
      display: block !important;
    }
  }
`
