import css from 'styled-jsx/css'

import { breakpoints, typography } from '@lno/constants'

export const styles: any = css`
  .option-item {
    display: flex;
    align-items: flex-start;
    padding: 1rem 0 1rem;
  }

  .option-item:last-child {
    padding-bottom: 0;
  }

  .option-item__icon {
    width: 4.25rem;
    margin-right: 1rem;
    height: auto;
  }

  .option-item__title {
    padding-bottom: 0.5rem;
  }

  .option-item__link {
    color: var(--fonts-accent);
    text-decoration: none;
  }

  @media ${breakpoints.tablet} {
    .option-item {
      max-width: 15.25rem;
      display: block;
      width: 16rem;
      text-align: center;
    }

    .option-item:not(:last-child) {
      margin-right: 1.5rem;
    }

    .option-item__icon {
      width: auto;
      height: 5.75rem;
      margin: 0 0 1rem 0;
    }
  }

  @media ${breakpoints.desktop} {
    .option-item {
      width: 21.875rem;
    }

    .option-item__icon {
      margin-right: 1rem;
    }

    .option-item:not(:last-child) {
      margin-right: 3rem;
    }

    .option-item__description {
      ${typography.bodyM()}
    }
  }
`
