import css from 'styled-jsx/css'

export const styles: any = css`
  .hand-on-the-wheel {
    overflow: hidden;
    background: linear-gradient(
      180deg,
      rgb(255, 77, 159) 0%,
      rgba(255, 1, 115, 1) 100%
    );
    padding: 1.5rem 0;
  }

  .hand-on-the-wheel_footer {
    max-width: 1170px;
    margin: 2rem auto 0;
  }

  .hand-on-the-wheel_inner-container {
    display: grid;
    justify-content: space-between;
    align-content: center;
    gap: 0;
    margin: 0 auto;
    max-width: 1170px;
    grid-template-columns: auto auto auto;
    grid-auto-flow: row;
    grid-template-areas: 'hand-on-the-wheel_left hand-on-the-wheel_center hand-on-the-wheel_right .';
  }

  .hand-on-the-wheel_center {
    grid-area: hand-on-the-wheel_center;
    margin: auto 0;
  }

  .hand-on-the-wheel_right {
    grid-area: hand-on-the-wheel_right;
  }

  .hand-on-the-wheel_left {
    grid-area: hand-on-the-wheel_left;
  }

  .hand-on-the-wheel_title {
    margin-bottom: 1rem;
  }

  .hand-on-the-wheel_button {
    max-width: 16.125rem;
    background: white;
    color: var(--theme-accent);
  }

  .hand-on-the-wheel_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.375rem;
  }

  .hand-on-the-wheel_left,
  .hand-on-the-wheel_right {
    display: flex;
    flex-direction: column;
  }

  .hand-on-the-wheel_left {
    max-width: 16.875rem;
  }

  .hand-on-the-wheel_col {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .hand-on-the-wheel_text {
    width: fit-content;
    font-weight: normal;
  }

  .hand-on-the-wheel_money {
    margin: 0 auto;
    height: 6.313rem;
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .hand-on-the-wheel_benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.078rem solid white;
    border-radius: 1rem;
    padding: 0.75rem;
    gap: 1rem;
  }

  .hand-on-the-wheel_right {
    gap: 1.5rem;
    max-width: 23.5rem;
  }

  .hand-on-the-wheel_right li {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.875rem;
    font-size: 1.125rem;
    color: white;
  }

  .hand-on-the-wheel_right ol > li + li {
    margin-top: 1rem;
  }

  .hand-on-the-wheel_right ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    margin-top: 1.5rem;
  }

  @media (max-width: 1300px) {
    .hand-on-the-wheel {
      background: linear-gradient(
        180deg,
        rgb(255 77 159) 0%,
        rgba(255, 1, 115, 1) 100%
      );
      padding: 2rem 0;
    }

    .hand-on-the-wheel_inner-container {
      grid-template-columns: 33.625rem;
      justify-content: center;
      gap: 1rem 0;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        'hand-on-the-wheel_center .'
        'hand-on-the-wheel_left .'
        'hand-on-the-wheel_right .';
    }

    .hand-on-the-wheel_footer {
      max-width: 25.375rem;
      margin-top: 2rem;
    }

    .hand-on-the-wheel_left,
    .hand-on-the-wheel_right {
      max-width: 24.375rem;
      margin: 0 auto;
    }

    .hand-on-the-wheel_download {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .hand-on-the-wheel_benefits {
      max-width: 22.375rem;
      margin: 0 auto;
    }

    .hand-on-the-wheel_right:before {
      content: '';
      margin: 0 auto;
      width: 4rem;
      height: 0.25rem;
      border-radius: 6.25rem;
      background-color: rgba(255, 255, 255, 0.75);
    }

    .hand-on-the-wheel_row {
      gap: 1.375rem;
    }

    .hand-on-the-wheel_title {
      font-size: 1.25rem;
      line-height: normal;
    }

    .hand-on-the-wheel_paragraph {
      font-size: 1rem;
      line-height: normal;
      padding: 0 !important;
    }

    .hand-on-the-wheel_disclaimer {
      text-align: center;
    }

    .hand-on-the-wheel_center {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: auto;
        height: 17.5rem;
        object-fit: scale-down;
      }
    }
  }

  @media (max-width: 590px) {
    .hand-on-the-wheel {
      padding: 2rem 2rem 1rem;
    }

    .hand-on-the-wheel_disclaimer {
      text-align: center;
      margin: 0 auto;
    }
  }

  @media (max-width: 380px) {
    .hand-on-the-wheel_inner-container {
      grid-template-columns: auto;
    }

    .hand-on-the-wheel_center {
      img {
        width: 250px;
        height: auto;
      }
    }
  }

  @media (max-width: 280px) {
    .hand-on-the-wheel_download > ul {
      flex-direction: column;
      gap: 1rem;
    }
  }
`
