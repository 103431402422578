/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react'

import { calcRemainingTime } from '@lno/core/hooks/useRemainingTimeCounter/utils/time'
import { accurateInterval, Subscription } from '@lno/core/utils/interval'
import { getPayloadTimer } from './utils/payload'
import { ONE_SECOND_TIMESTAMP } from '@lno/core/constants/time'

interface Props {
  startTimeCampaign: number
  endTimeCampaign: number
  startStopWatch: number
}

export const useCampaignTimer = ({
  startTimeCampaign,
  endTimeCampaign,
  startStopWatch,
}: Props) => {
  const intervalRef = useRef<Subscription>()

  const [payload, setPayload] = useState(() =>
    getPayloadTimer({
      periodBetweenEndStart: calcRemainingTime(endTimeCampaign),
      startStopWatch,
      startTimeCampaign,
    })
  )

  const startCounter = useCallback(() => {
    const interval = accurateInterval(ONE_SECOND_TIMESTAMP)

    intervalRef.current = interval.subscribe(() => {
      const diffDates = calcRemainingTime(endTimeCampaign)

      setPayload(
        getPayloadTimer({
          periodBetweenEndStart: diffDates,
          startStopWatch,
          startTimeCampaign,
        })
      )

      if (diffDates === 0) intervalRef.current!.unsubscribe()
    })
  }, [endTimeCampaign])

  useEffect(() => {
    startCounter()

    return () => {
      intervalRef.current?.unsubscribe()
    }
  }, [startCounter])

  return payload
}
