import { scopedClsx } from '@lno/core/utils/styled'
import { styles } from '../styles'
import { CardTitleProps } from './types'
const cl = scopedClsx(styles)

export const CardsTitle = ({
  showTitle,
  subtitle,
  label,
  className,
}: CardTitleProps) => (
  <>
    {showTitle && (
      <div className={cl('partner__text', className)}>
        <div
          className={cl('partner__title', className)}
          dangerouslySetInnerHTML={{
            __html: label,
          }}
        />
        {subtitle && (
          <div
            className={cl('partner__subtitle')}
            dangerouslySetInnerHTML={{
              __html: subtitle,
            }}
          />
        )}
      </div>
    )}
  </>
)
