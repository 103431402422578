import { GASelectEventsPayloads } from '@lno/core/modules/analytics/constants'
import { useIsContentInViewport } from '@lno/core/modules/analytics/hooks/useIsContentInViewport'
import { viewContentEvent } from '@lno/core/modules/analytics/utils/viewContentEvent'
import { MutableRefObject, useCallback, useRef } from 'react'

interface Props {
  label: string
  ref: MutableRefObject<HTMLElement | null>
}

export const useHeroEvent = ({ label, ref }: Props) => {
  const isEventSent = useRef<boolean>(false)

  const handleCardInViewport = useCallback(() => {
    if (!isEventSent.current) {
      const payload = {
        ...GASelectEventsPayloads.viewHeroSection,
        label,
      }
      viewContentEvent(payload)
      isEventSent.current = true
    }
  }, [label])

  useIsContentInViewport({ ref, callback: handleCardInViewport })
}
