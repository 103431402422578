import step1 from './StepList/images/step-1.svg'
import step2 from './StepList/images/step-2.svg'
import step3 from './StepList/images/step-3.svg'

export const STEPS = [
  {
    id: 1,
    title: 'Confira suas dívidas negativadas e contas atrasadas disponíveis',
    img: step1,
  },
  {
    id: 2,
    title: 'Escolha a melhor opção e conclua o acordo',
    img: step2,
  },
  {
    id: 3,
    title: 'Agora é pagar via boleto ou Pix, se disponível',
    img: step3,
  },
]

export const STEPS_CAMPAIGN = [
  {
    id: 1,
    title: 'Confira suas dívidas negativadas e contas atrasadas disponíveis',
    img: step1,
  },
  {
    id: 2,
    title: 'Escolha a melhor opção e conclua o acordo',
    img: step2,
  },
  {
    id: 3,
    title: 'Agora é só pagar o boleto',
    img: step3,
  },
]
