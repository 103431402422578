import { Typography } from '@lno/components/Typography'
import { TypographyTag } from '@lno/core/components/Typography/types'
import clsx from 'clsx'
import { styles } from '../styles'

const jsxScope = `jsx-${styles.__hash}`

type Text = { text?: string; tag?: TypographyTag }

export const DesenrolaBrasilTitle = ({ title }: { title?: Text }) => {
  if (!title) return null

  return (
    <Typography
      size="headingM"
      color="high"
      className={clsx(jsxScope, 'debt-negotiation-program-section__title')}
      tag={title.tag || 'h1'}
    >
      {title.text}
    </Typography>
  )
}

export const DesenrolaBrasilDescription = ({ content }: { content?: Text }) => {
  if (!content) return null

  return (
    <div
      className={clsx(
        jsxScope,
        'debt-negotiation-program-section__description'
      )}
    >
      <Typography
        size="bodyM"
        color="medium"
        tag={content.tag || 'h2'}
        dangerouslySetInnerHTML={{ __html: content.text }}
      />
    </div>
  )
}
