import css from 'styled-jsx/css'

export const styles: any = css`
  .post {
    max-width: 16.5rem;
    min-height: 10rem;

    & + & {
      margin-left: 1rem;
    }

    &__link {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      outline-offset: 2px;
      background-color: #fff;
      border: 1px solid rgb(236, 236, 236);
      height: 17.75rem;
      border-radius: 0.5rem;
      overflow: hidden;
    }

    &__image-wrapper {
      display: flex;
      flex-direction: column;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      overflow: hidden;
      width: 100%;
      height: 10.375rem;
      margin-bottom: 1rem;
      padding: 0px;
    }

    &__image {
      width: 100%;
    }

    &__title {
      color: rgba(0, 8, 38, 0.8);
      font-size: 1rem;
      line-height: 1.25rem;
      transition: opacity 0.3s ease-in-out 0s;
      padding: 0px 1rem;
      margin-bottom: 1rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    &__button {
      font-size: 1rem;
      margin-top: auto;
      margin-bottom: 1rem;
    }
  }
`
