import clsx from 'clsx'
import Lazy from 'react-lazyload'
import { Partner } from '../types'

import { styles } from './styles'

type PartnerItemProps = {
  partner: Partner
  className?: string
}

export const PartnerItem = (props: PartnerItemProps) => {
  const { partner, className } = props
  const { partnerKey, hasBlacklabel, blacklabelUrl, companyName } = partner
  const image1x = `https://www.serasa.com.br/lno/static-webfiles/partners/${partnerKey}@1x.png`
  const image2x = `https://www.serasa.com.br/lno/static-webfiles/partners/${partnerKey}@2x.png`
  const linkUrl = `/limpa-nome-online/parceiros/${blacklabelUrl || partnerKey}/`

  return (
    <div className={clsx(className, 'partner-item')}>
      <Lazy height={59} once style={{ width: 88, height: 59 }}>
        {hasBlacklabel ? (
          <a
            href={linkUrl}
            title={`Negociação de dívida: ${companyName}`}
            className="partner-item__link"
          >
            <img
              src={image1x}
              srcSet={`${image1x}, ${image2x} 2x`}
              alt={`Negociação de dívida: ${companyName}`}
              role="presentation"
              className="partner-item__img"
              width="88"
              height="59"
            />
          </a>
        ) : (
          <img
            src={image1x}
            srcSet={`${image1x}, ${image2x} 2x`}
            alt={`Negociação de dívida: ${companyName}`}
            className="partner-item__img"
            width="88"
            height="59"
          />
        )}
      </Lazy>
      <style jsx>{styles}</style>
    </div>
  )
}

PartnerItem.defaultProps = {
  className: '',
  hasBlacklabel: false,
  blacklabelUrl: null,
  companyName: '',
  title: null,
}

export default PartnerItem
