import numberOfDiscountImage from '../images/number-of-discount.svg'
import numberOfInstallmentsImage from '../images/number-of-installments.svg'
import pixImage from '../images/pix.svg'

export const IMAGE_TEXT = {
  pix: 'PIX',
  '72x': 'setenta e duas vezes',
  '99-percent': 'noventa e nove porcento',
}

export function getArgumentsContent(isMobile: boolean) {
  const IMAGES = {
    pix: {
      src: pixImage,
      height: isMobile ? '50' : '65',
      width: isMobile ? '52' : '70',
      description: 'pix' as const,
    },
    '72x': {
      src: numberOfInstallmentsImage,
      height: isMobile ? '50' : '65',
      width: isMobile ? '59' : '79',
      description: '72x' as const,
    },
    '99-percent': {
      src: numberOfDiscountImage,
      height: isMobile ? '50' : '65',
      width: isMobile ? '62' : '70',
      description: '99-percent' as const,
    },
  }

  return {
    pix: {
      text: 'Nome limpo na hora com',
      image: IMAGES.pix,
    },
    '72x': {
      text: 'Parcelas de até',
      image: IMAGES['72x'],
    },
    '99-percent': {
      text: 'Descontos de até',
      image: IMAGES['99-percent'],
    },
  }
}
