import Link from '@lno/core/components/Link'
import clsx from 'clsx'
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react'
import { styles } from './styles'
export const jsxScope = `jsx-${styles.__hash}`

interface HightlightedLinkProps
  extends DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {}

export const HightlightedLink = ({
  children,
  ...props
}: HightlightedLinkProps) => (
  <>
    <Link className={clsx(jsxScope, 'highlighted-link')} {...props} external>
      {children}
    </Link>
    <style jsx>{styles}</style>
  </>
)
