import { breakpoints } from '@lno/core/constants'
import css from 'styled-jsx/css'

export const styles: any = css`
  .partners-section {
    margin: 2rem auto 0;
    max-width: 75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    color: rgba(52, 57, 80, 1);
    padding: 0 1.5rem;
    @media ${breakpoints.desktop} {
      margin: 6.5rem auto 0;
    }
  }

  .partners-section__title {
    text-align: center;
    font-size: 2.5rem;
    line-height: 2.75rem;
    @media ${breakpoints.desktopDown} {
      font-size: 2rem;
      line-height: 2.25rem;
      letter-spacing: -1px;
    }
  }

  .partners-section__cta {
    margin: 1rem 0 2rem;
    text-align: center;
    &:hover {
      box-shadow: 0 0 0 1px var(--theme-pink);
      color: #e94e95;
    }
    @media ${breakpoints.desktopDown} {
      max-width: 16.75rem;
    }
  }
`
