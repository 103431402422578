import LazyLoad from 'react-lazyload'
import clsx from 'clsx'

import { Typography } from '@lno/components/Typography'

import { styles } from './styles'
import { useCampaign } from '@lno/core/hooks/useCampaign'
import { VisuallyHidden } from '@lno/core/components/VisuallyHidden'

type StepItemProps = {
  id: number
  title: string
  img: string
}

const jsxScope = `jsx-${styles.__hash}`

export const StepItem = ({ id, title, img }: StepItemProps) => {
  const { isActiveCampaign } = useCampaign()
  const screenReaderStep = `Passo ${id}: ${title}`
  return (
    <li className="step">
      <Typography
        size="headingL"
        tag="h3"
        className={clsx(jsxScope, 'step__number', {
          'step__number--active_campaign': isActiveCampaign,
        })}
      >
        <VisuallyHidden>{screenReaderStep}</VisuallyHidden>
        <span aria-hidden>{`0${id}`}</span>
      </Typography>
      <Typography
        className={clsx(jsxScope, 'step__title')}
        size="bodyP"
        color="medium"
        tag="p"
        role="text"
        aria-hidden
      >
        {title}
      </Typography>

      <LazyLoad height={210} offset={1500}>
        <img
          src={img}
          alt=""
          className="step__img"
          draggable="false"
          width="236"
          height="236"
        />
      </LazyLoad>

      <style jsx>{styles}</style>
    </li>
  )
}

export default StepItem
