import ButtonLink from '@lno/core/components/ButtonLink'
import { LN_LOGIN_URL } from '@lno/core/constants'
import type { Hero } from '@lno/core/models/Hero'
import { GASelectContentEvent } from '@lno/core/modules/analytics/events'
import clsx from 'clsx'
import { styles } from '../styles'
const jsxScope = `jsx-${styles.__hash}`

type CtaProps = Pick<Hero, 'cta'> & { event: string }

export const CtaBlock = ({ cta, event }: CtaProps) => {
  const handleClick = () => {
    return GASelectContentEvent.checkOffersHero(event)
  }

  return (
    <>
      <div className="cms-hero__cta-wrapper">
        <div className="cms-hero__cta-wrapper__box">
          <ButtonLink
            className={clsx(jsxScope, 'cms-hero__cta')}
            color="primary"
            variant="outlined"
            href={LN_LOGIN_URL}
            onClick={handleClick}
            role="button"
          >
            {cta.button.label}
          </ButtonLink>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  )
}
