import { LN_WEBFILES_LANDING } from '@lno/constants'

import postContatoSerasa from '@assets/images/feirao/post-contato-serasa.png'
import postContatoSerasaWebp from '@assets/images/feirao/post-contato-serasa.webp'

import postParticiparLno from '@assets/images/feirao/post-participar-lno.png'
import postParticiparLnoWebp from '@assets/images/feirao/post-participar-lno.webp'

import postGolpesLno from '@assets/images/feirao/post-golpes-lno.png'
import postGolpesLnoWebp from '@assets/images/feirao/post-golpes-lno.webp'

const imgWhatsapp = `${LN_WEBFILES_LANDING}/ensina/whatsapp.png`
const imgConsultarDividas = `${LN_WEBFILES_LANDING}/ensina/consultar-dividas.png`
const imgProtestoCartorio = `${LN_WEBFILES_LANDING}/ensina/protesto-cartorio.png`

export const POSTS = [
  {
    title: 'Negocie suas dívidas no WhatsApp Serasa',
    url: 'https://www.serasa.com.br/limpa-nome-online/blog/whatsapp',
    image: imgWhatsapp,
    category: 'SEU NOME LIMPO',
    categoryColor: 'var(--theme-primary)',
    alt: 'Negocie suas dívidas no WhatsApp Serasa',
  },
  {
    title: 'Consultar dívidas CNPJ: Pague os débitos da sua empresa',
    url: 'https://www.serasa.com.br/limpa-nome-online/blog/consultar-dividas-CNPJ/',
    image: imgConsultarDividas,
    category: 'SEU NOME LIMPO',
    categoryColor: 'var(--theme-primary)',
    alt: 'Consultar dívidas CNPJ: Pague os débitos da sua empresa',
  },
  {
    title: 'Protesto em cartório: como descobrir a origem e como regularizar',
    url: 'https://www.serasa.com.br/limpa-nome-online/blog/protesto-em-cartorio-como-regularizar/',
    image: imgProtestoCartorio,
    category: 'SEU NOME LIMPO',
    categoryColor: 'var(--theme-primary)',
    alt: 'Protesto em cartório: como descobrir a origem e como regularizar',
  },
]

export const POSTS_CAMPAIGN = [
  {
    title: 'Como participar do Feirão Serasa Limpa Nome 2023?',
    url: 'https://www.serasa.com.br/limpa-nome-online/blog/como-aproveitar-o-feirao-serasa-limpa-nome-2020/',
    image: postParticiparLno,
    imageWebp: postParticiparLnoWebp,
    category: 'NEGOCIAR DÍVIDA',
    categoryColor: 'var(--theme-primary)',
    alt: 'Pessoa procurando saber como participar do feirão limpa nome 2023',
  },
  {
    title: '5 dicas para evitar golpes no período do Feirão Limpa Nome',
    url: 'https://www.serasa.com.br/premium/blog/4-dicas-para-evitar-golpes-no-periodo-do-feirao-limpa-nome/',
    image: postGolpesLno,
    imageWebp: postGolpesLnoWebp,
    category: 'SEGURANÇA NA INTERNET',
    categoryColor: 'var(--theme-primary)',
    alt: 'Homem anotando as dias para evitar golpes do feirão limpa nome',
  },
  {
    title: 'Saiba como entrar em contato com a Serasa',
    url: 'https://www.serasa.com.br/blog/como-entrar-em-contato-com-a-serasa/',
    image: postContatoSerasa,
    imageWebp: postContatoSerasaWebp,
    category: 'EDUCAÇÃO FINANCEIRA',
    categoryColor: 'var(--theme-primary)',
    alt: 'Mulher navegando no celular e sorrindo',
  },
]
