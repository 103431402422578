import { LN_WEBFILES_LANDING } from '@lno/constants'

const imgG1 = `${LN_WEBFILES_LANDING}/media/G1.png`
const imgG12x = `${LN_WEBFILES_LANDING}/media/G1@2x.png`

const imgCNN1x = `${LN_WEBFILES_LANDING}/media/CNN.png`
const imgCNN2x = `${LN_WEBFILES_LANDING}/media/CNN@2x.png`

const imgEXTRA1x = `${LN_WEBFILES_LANDING}/media/Extra.png`
const imgEXTRA2x = `${LN_WEBFILES_LANDING}/media/Extra@2x.png`

const imgISTOE1x = `${LN_WEBFILES_LANDING}/media/ISTOE.png`
const imgISTOE2x = `${LN_WEBFILES_LANDING}/media/ISTOE@2x.png`

const imgUOL1x = `${LN_WEBFILES_LANDING}/media/UOL.png`
const imgUOL2x = `${LN_WEBFILES_LANDING}/media/UOL@2x.png`

const imgMEIOEMENSAGEM1x = `${LN_WEBFILES_LANDING}/media/MEIOEMENSAGEM.png`
const imgMEIOEMENSAGEM2x = `${LN_WEBFILES_LANDING}/media/MeioeMensagem@2x.png`

const imgVEJA1x = `${LN_WEBFILES_LANDING}/media/VEJA.png`
const imgVEJA2x = `${LN_WEBFILES_LANDING}/media/veja@2x.png`

const MEDIAS = [
  {
    id: 1,
    url: 'https://g1.globo.com/economia/educacao-financeira/noticia/2020/09/08/campanha-da-serasa-para-limpar-nome-por-r-100-termina-nesta-terca.ghtml',
    title:
      'G1: Campanha da Serasa para limpar nome por R$ 100 termina nesta terça. Campanha permitiu a quitação de 5 milhões de débitos entre R$ 200,00 e R$ 1.000,00.',
    tag: 'G1: Campanha da Serasa para limpar nome por R$ 100 termina nesta terça',
    img1x: imgG1,
    img2x: imgG12x,
  },
  {
    id: 2,
    url: 'https://www.cnnbrasil.com.br/business/2020/07/31/serasa-lanca-campanha-para-limpar-nome-de-endividados-pagando-cem-reais',
    title:
      'CNN: Serasa lança campanha para limpar nome de endividados pagando cem reais. O Serasa Limpa Nome já está em andamento há algumas semanas, contando com a adesão de bancos, redes varejistas, empresas de energia elétrica e universidades, entre outras.',
    tag: 'CNN: Serasa lança campanha para limpar nome de endividados pagando cem reais.',
    img1x: imgCNN1x,
    img2x: imgCNN2x,
  },
  {
    id: 3,
    url: 'https://extra.globo.com/economia/veja-como-renegociar-dividas-pelo-whatsapp-durante-pandemia-rv1-1-24626493.html',
    title:
      'Extra: Veja como renegociar dívidas pelo WhatsApp durante a pandemia. O aplicativo de mensagens instantâneas é usado pela Serasa nas renegociações desde março, e a adesão a esta via cresce desde então.',
    tag: 'Extra: Veja como renegociar dívidas pelo WhatsApp durante a pandemia.',
    img1x: imgEXTRA1x,
    img2x: imgEXTRA2x,
  },
  {
    id: 4,
    url: 'https://www.istoedinheiro.com.br/feirao-limpa-nome-da-serasa-e-prorrogado-ate-21-de-dezembro/',
    title: 'Istoé: Feirão Limpa Nome da Serasa é prorrogado até 21 de dezembro',
    tag: 'Istoé',
    img1x: imgISTOE1x,
    img2x: imgISTOE2x,
  },
  {
    id: 5,
    url: 'https://economia.uol.com.br/noticias/redacao/2020/11/09/serasa-libera-mais-de-1o-milhoes-de-dividas-para-serem-quitadas-por-r-50.htm',
    title:
      'UOL: Serasa libera mais de 10 milhões de dívidas para serem quitadas por 50 reais',
    tag: 'UOL',
    img1x: imgUOL1x,
    img2x: imgUOL2x,
  },
  {
    id: 6,
    url: 'https://www.meioemensagem.com.br/home/marketing/2020/03/04/serasa-detalha-parceria-com-o-corinthians.html',
    title: 'MEIO E MENSAGEM: Serasa detalha parceria com o Corinthians',
    tag: 'MEIO E MENSAGEM',
    img1x: imgMEIOEMENSAGEM1x,
    img2x: imgMEIOEMENSAGEM2x,
  },
  {
    id: 7,
    url: 'https://vejasp.abril.com.br/cidades/serasa-feirao-limpa-nome-novembro/',
    title: 'Veja: Serasa realiza novo Feirão Limpa Nome em novembro',
    tag: 'Veja',
    img1x: imgVEJA1x,
    img2x: imgVEJA2x,
  },
]

export default MEDIAS
