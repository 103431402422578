const getTimeByProcessENV = (env: string) => new Date(env).getTime()

export const FIRST_DAY_INTEREST_FREE_CAMPAIGN = getTimeByProcessENV(
  process.env.FIRST_DAY_INTEREST_FREE_CAMPAIGN!
)

export const FIRST_DAY_INTEREST_FREE_CAMPAIGN_START_STOPWATCH =
  getTimeByProcessENV(
    process.env.FIRST_DAY_INTEREST_FREE_CAMPAIGN_START_STOPWATCH!
  )

export const LAST_DAY_INTEREST_FREE_CAMPAIGN = getTimeByProcessENV(
  process.env.LAST_DAY_INTEREST_FREE_CAMPAIGN!
)

export const FIRST_DAY_EXTENDED_INTEREST_FREE_CAMPAIGN = getTimeByProcessENV(
  process.env.FIRST_DAY_EXTENDED_INTEREST_FREE_CAMPAIGN!
)

export const LAST_DAY_EXTENDED_INTEREST_FREE_CAMPAIGN = getTimeByProcessENV(
  process.env.LAST_DAY_EXTENDED_INTEREST_FREE_CAMPAIGN!
)
