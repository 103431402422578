import type { ReactNode } from 'react'
import { ListItemWithIcon } from './ListItemWithIcon'

import { styles } from './styles'

type HorizontalListWithIconsProps = {
  items: {
    title: string
    image: string
    description: string | ReactNode
  }[]
}

export const HorizontalListWithIcons = ({
  items,
}: HorizontalListWithIconsProps) => (
  <ol className="horizontal-list">
    {items.map((item, index) => (
      <ListItemWithIcon key={index} {...item} />
    ))}

    <style jsx>{styles}</style>
  </ol>
)

export default HorizontalListWithIcons
