import css from 'styled-jsx/css'

import { breakpoints } from '@lno/constants'

export const styles: any = css`
  .deals-options-section {
    padding: 8rem 1.5rem 2rem;
  }

  .deals-options-section__title {
    text-align: center;
    margin-bottom: 1rem;
  }

  @media ${breakpoints.tablet} {
    .deals-options-section__title {
      width: 34.25rem;
      margin: 0 auto;
    }
  }

  @media ${breakpoints.desktop} {
    .deals-options-section__title {
      width: 100%;
      margin-bottom: 1rem;
    }

    .deals-options-section {
      padding: 2rem 1.5rem;
    }
  }
`
