import clsx from 'clsx'

import imgLogoEcred from '@assets/images/logo_serasa_credito_rosa.png'
import imgLogoEcredWebp from '@assets/images/logo_serasa_credito_rosa.webp'
import { ButtonLink } from '@lno/components/ButtonLink'
import { Grid } from '@lno/components/Grid'
import { Typography } from '@lno/components/Typography'

import { GASelectContentEvent } from '@lno/core/modules/analytics/events'
import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

export const CrossEcred = () => (
  <section className="cross-ecred">
    <Grid>
      <Typography
        size="headingM"
        color="high"
        weight="bold"
        className={clsx(jsxScope, 'cross-ecred__title')}
        tag="h2"
      >
        Precisa de dinheiro para quitar sua dívida?
      </Typography>
      <div className="cross-ecred__content">
        <picture>
          <source srcSet={`${imgLogoEcredWebp}`} type="image/webp" />
          <img
            className="cross-ecred__logo"
            alt="Logo eCred"
            src={imgLogoEcred}
            loading="lazy"
            aria-hidden
          />
        </picture>
        <Typography
          tag="p"
          size="bodyM"
          color="high"
          className={clsx(jsxScope, 'cross-ecred__description')}
        >
          No Serasa Crédito, você simula empréstimo com juros a partir de 1.09%
          ao mês
        </Typography>
      </div>
      <ButtonLink
        onClick={GASelectContentEvent.simulateLoans}
        className={clsx(jsxScope, 'cross-ecred__link')}
        color="accent"
        variant="outlined"
        href="https://www.serasa.com.br/ecred/simulador/?cross_source=lno-web"
        target="_blank"
        role="button"
      >
        Simular empréstimos
      </ButtonLink>
    </Grid>

    <style jsx>{styles}</style>
  </section>
)

export default CrossEcred
