import imgApp from '../../images/dealsOptions/app.svg'
import imgWhatsapp from '../../images/dealsOptions/whatsapp.png'
import imgCorreios from '../../images/dealsOptions/correios.png'

export const OPTIONS = {
  APP: {
    title: 'Aplicativo Serasa',
    image: imgApp,
  },
  WHATSAPP: {
    title: 'WhatsApp Serasa oficial',
    image: imgWhatsapp,
  },
  MAIL: {
    title: 'Serasa nos Correios',
    image: imgCorreios,
  },
}
