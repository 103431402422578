import { DesenrolaBrasilImage } from './DesenrolaImage'

import {
  DesenrolaBrasilDescription,
  DesenrolaBrasilTitle,
} from './DesenrolaText'

export const DesenrolaBrasil = {
  Title: DesenrolaBrasilTitle,
  Description: DesenrolaBrasilDescription,
  Image: DesenrolaBrasilImage,
}
