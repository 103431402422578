import clsx from 'clsx'

import { Grid } from '@lno/components/Grid'
import { Typography } from '@lno/components/Typography'
import OptionsList from './OptionsList'

import { useCampaign } from '@lno/core/hooks/useCampaign'
import { styles } from './styles'

const jsxScope = `jsx-${styles.__hash}`

export const DealsOptionsSection = () => {
  const { isActiveCampaign } = useCampaign()
  return (
    <section className="deals-options-section">
      <Grid>
        <Typography
          size="headingM"
          color="high"
          className={clsx(jsxScope, 'deals-options-section__title')}
          tag="h2"
          align="center"
        >
          {!isActiveCampaign
            ? 'Você escolhe por onde negociar suas dívidas'
            : 'Onde negociar dívidas no MegaFeirão Serasa Limpa Nome?'}
        </Typography>

        {!isActiveCampaign && (
          <div>
            <Typography size="bodyM" color="low" align="center" tag="p">
              Além daqui, você pode negociar suas dívidas nos seguintes canais:
            </Typography>
          </div>
        )}

        <OptionsList />
      </Grid>

      <style jsx>{styles}</style>
    </section>
  )
}

export default DealsOptionsSection
