export enum PartnerTypes {
  Gold = 'Gold',
  Silver = 'Silver',
  Bronze = 'Bronze',
  Others = 'Others',
}
export enum TranslatedPartnerCategory {
  Gold = 'ouro',
  Silver = 'prata',
  Bronze = 'bronze',
  Others = 'outro',
}
